<template>
    <a-modal class="milePop" title="Color Picker" :width="800" v-model="visible" ok-text="Confirm" @ok="confirm">
        <div class="flexbox">
            <div class="color-block" :style="{ backgroundColor: selected.hex }"></div>
            <sketch-picker v-model="selected" style="width: 269px" />
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';
import { Sketch } from 'vue-color';

export default Vue.extend({
    name: 'ColorCode',
    components: {
        'sketch-picker': Sketch,
    },
    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: false,
            selected: {},
        };
    },
    watch: {
        active: {
            handler(newValue) {
                this.selected = { hex: newValue };
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        confirm() {
            this.visible = false;
            this.$emit('confirm', this.selected.hex);
        },
    },
});
</script>
<style scoped lang="less">
.flexbox {
    display: flex;
    justify-content: space-around;
    .color-block {
        width: 269px;
        height: 269px;
        border-radius: 8px;
        background-color: rgba(255, 149, 0, 1);
    }
}
</style>
