<template>
    <div class="cate" :style="{ backgroundColor: configure.category_color == 'filled' ? configure.color_palette.color3 : '' }">
        <a-icon type="left" style="margin-right: 24px" :style="{ color: configure.color_palette.color2 }" />
        <div class="list">
            <div class="item" :style="{ color: configure.color_palette.color2, fontWeight: 'bold' }">
                <span :class="['cate-icon', 'iconfont', `icon-cateleft${configure.theme_name}`]" :style="{ color: configure.color_palette.color2 }"></span>
                Category
                <span :class="['cate-icon', 'iconfont', `icon-cateright${configure.theme_name}`]" :style="{ color: configure.color_palette.color2 }"></span>
            </div>
            <div class="item" v-for="item in 5" :key="item" :style="{ color: configure.color_palette.color2 }">Category</div>
        </div>
        <a-icon type="right" :style="{ color: configure.color_palette.color2 }" />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'CatePreview',
    components: {},
    props: {},
    data() {
        return {
            bgClass: {},
        };
    },
    watch: {
        configure: {
            handler: function (newValue) {
                this.bgClass = {
                    background: newValue.background_cover.indexOf('#') == 0 ? newValue.background_cover : `url(${newValue.background_cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
    },
});
</script>
<style scoped lang="less">
.cate {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 26px;
    width: 100%;
    height: 63px;
    color: #000;
    font-size: 16px;
    .list {
        display: flex;
        .item {
            display: flex;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            .cate-icon {
                font-size: 24px;
            }
        }
    }
}
</style>
