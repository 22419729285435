<template>
    <a-modal class="milePop" title="Menu Name" :width="400" v-model="visible" ok-text="Confirm" @ok="confirm">
        <a-input v-model="name" placeholder="Menu name" size="large" :max-length="30" />
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';

export default Vue.extend({
    name: 'Rename',
    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: false,
            name: '',
        };
    },
    watch: {
        active: {
            handler(newValue) {
                this.name = newValue;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        confirm() {
            this.$emit('confirm', this.name);
            this.visible = false;
        },
    },
});
</script>
<style scoped lang="less"></style>
