<template>
    <a-modal class="milePop" :title="popTitle[step - 1]" :width="1100" v-model="visible" :footer="null" :key="key" @cancel="onCancel">
        <div class="step" v-show="step == 1">
            <div class="info">What is the name of this menu? You can also change it later</div>
            <a-input class="name" placeholder="Menu name" size="large" v-model="currentConfigure.name" />
            <div class="tip">*this will be used for your management and displayed on the device to guests.</div>
            <div class="dividing" style="margin: 28px 0 34px"></div>
            <div class="info">Would you like it vertical or horizontal?</div>
            <div class="direction">
                <div :class="['item', currentConfigure.direction == 'vertical' ? 'active' : '']" @click="currentConfigure.direction = 'vertical'">
                    <div class="cube vertical"></div>
                    <div class="text">vertical</div>
                </div>
                <div :class="['item', currentConfigure.direction == 'horizontal' ? 'active' : '']" @click="currentConfigure.direction = 'horizontal'">
                    <div class="cube horizontal"></div>
                    <div class="text">horizontal</div>
                </div>
            </div>
        </div>
        <div class="step" v-show="step == 2">
            <div class="info">Select one of the following to be your menu’s theme, you can customize it later</div>
            <div class="theme-box">
                <div :class="['item', activeTheme == item.theme_name ? 'active' : '']" v-for="(item, key) in THEME_LIST" :key="item.theme_name" @click="activeTheme = item.theme_name">
                    <img class="theme-img" :style="{ borderColor: item.color_palette.color3 }" :src="item.background_cover.vertical" alt="" />
                    <div class="theme-name" :style="{ color: themeNameColor[key] }">{{ item.theme_name }}</div>
                    <div class="selected">selected</div>
                </div>
            </div>
        </div>
        <div class="step" v-show="step == 3">
            <div class="info">You can also change this later</div>
            <div class="layout-box">
                <div :class="['item', currentConfigure.category_layout == 'top' ? 'active' : '']" @click="currentConfigure.category_layout = 'top'">
                    <div class="img-box">
                        <img class="theme-img" :src="layout1" alt="" />
                    </div>
                    <div class="selected">selected</div>
                </div>
                <div :class="['item', currentConfigure.category_layout == 'bottom' ? 'active' : '']" @click="currentConfigure.category_layout = 'bottom'">
                    <div class="img-box">
                        <img class="theme-img" :src="layout2" alt="" />
                    </div>
                    <div class="selected">selected</div>
                </div>
                <div
                    :class="['item', currentConfigure.category_layout == 'left' ? 'active' : '']"
                    @click="currentConfigure.category_layout = 'left'"
                    v-show="currentConfigure.direction == 'horizontal'"
                >
                    <div class="img-box">
                        <img class="theme-img" :src="layout3" alt="" />
                    </div>
                    <div class="selected">selected</div>
                </div>
            </div>
        </div>
        <div class="step" style="text-align: center" v-show="step == 4">
            <div style="font-size: 34px; font-weight: bold">Let’s start building your menu</div>
            <a-button class="open-editor" type="primary" size="large" style="" @click="gotoEditor"> Open the Editor </a-button>
            <a-button type="link" size="large" @click="previous"> Previous Step </a-button>
        </div>
        <div class="button-box">
            <a-button type="link" @click="previous" v-show="step > 1 && step < 4"> Previous Step </a-button>
            <a-button type="primary" @click="next" v-show="step < 4"> Continue </a-button>
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import { CONFIGURE } from '@/utils/constant';
import { THEME_LIST } from '@/utils/theme';

export default Vue.extend({
    name: 'Starter',
    props: {},
    data() {
        return {
            THEME_LIST,
            layout1: require('@assets/images/layout1.png'),
            layout2: require('@assets/images/layout2.png'),
            layout3: require('@assets/images/layout3.png'),
            visible: false,
            step: 1,
            popTitle: ['Let’s get started…', 'Great, now choose a theme you like…', 'OK, now which layout would you like for your menu?', ''],
            key: 0,
            currentConfigure: {},
            activeTheme: 'basic',
            themeNameColor: {
                basic: '#000000',
                classic: '#C6A447',
                modern: '#ffffff',
            },
        };
    },
    watch: {
        key: {
            handler() {
                this.currentConfigure = this._.cloneDeep(CONFIGURE);
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
            set_editable: 'app/SET_EDITABLE',
        }),

        show() {
            this.visible = true;
        },
        onCancel() {
            this.key++;
            this.step = 1;
        },

        previous() {
            if (this.step > 1) {
                this.step--;
            }
        },
        next() {
            if (this.step == 1 && !this.currentConfigure.name) {
                this.$notification['warning']({
                    message: 'Please enter the menu name',
                });
                return;
            }
            if (this.step == 2) {
                this.currentConfigure.theme_name = this.activeTheme;
                this.currentConfigure.border_img = this.THEME_LIST[this.activeTheme].border_img;
                this.currentConfigure.quantity_border_img = this.THEME_LIST[this.activeTheme].quantity_border_img;
                this.currentConfigure.quantity_bg_img = this.THEME_LIST[this.activeTheme].quantity_bg_img;
                this.currentConfigure.category_img = this.THEME_LIST[this.activeTheme].category_img;
                this.currentConfigure.color_palette = this._.cloneDeep(this.THEME_LIST[this.activeTheme].color_palette);
                this.currentConfigure.font_family = this._.cloneDeep(this.THEME_LIST[this.activeTheme].font_family);
                this.currentConfigure.item_name_color = this.THEME_LIST[this.activeTheme].color_palette.color2;
                this.currentConfigure.background_cover = this.THEME_LIST[this.activeTheme].background_cover[this.currentConfigure.direction];
            }
            if (this.step == 3 && this.currentConfigure.category_layout == 'left' && this.currentConfigure.direction == 'vertical') {
                this.$notification['warning']({
                    message: 'Please choose layout',
                });
                return;
            }
            if (this.step < 4) {
                this.step++;
            }
        },
        gotoEditor() {
            this.set_editable(1);
            this.set_configure(this.currentConfigure);
            this.$router.push({ path: '/admin/editor' });
        },
    },
});
</script>
<style scoped lang="less">
.step {
    margin-top: 20px;
    .info {
        font-weight: bold;
    }
    .name {
        margin-top: 37px;
        width: 600px;
    }
    .tip {
        margin-top: 20px;
        font-size: 12px;
    }
    .direction {
        display: flex;
        margin-top: 30px;
        .item {
            display: flex;
            align-items: center;
            margin-right: 100px;
            cursor: pointer;
        }
        .item.active .cube {
            border-color: @main-color;
        }
        .item.active .text {
            color: @main-color;
        }
        .cube {
            border: 3px solid #fff;
            background-color: #d8d8d8;
        }
        .vertical {
            width: 68px;
            height: 98px;
        }
        .horizontal {
            width: 98px;
            height: 68px;
        }
        .text {
            margin-left: 16px;
        }
    }
    .theme-box {
        display: flex;
        margin-top: 70px;
        .item {
            margin-right: 70px;
            text-align: center;
            cursor: pointer;
        }
        .theme-img {
            width: 189px;
            height: 189px;
            border: 4px solid #fff;
            border-radius: 50%;
        }
        .theme-name {
            position: relative;
            margin-top: -59%;
            color: #ddd;
            font-size: 25px;
        }
        .selected {
            display: none;
            margin-top: 90px;
            color: @main-color;
            font-weight: bold;
        }

        .active .theme-img {
            border-color: @main-color !important;
        }
        .active .selected {
            display: block;
        }
    }
    .layout-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 60px;
        .item {
            text-align: center;
            cursor: pointer;
        }
        .selected {
            display: none;
            margin-top: 16px;
            color: @main-color;
            font-weight: bold;
            font-size: 22px;
        }
        .img-box {
            padding: 20px;
            width: 422px;
            height: 218px;
            border: 2px solid #fff;
            border-radius: 15px;
        }
        .item img {
            width: 100%;
            height: 100%;
        }
        .active .selected {
            display: block;
        }
        .active .img-box {
            border-color: @main-color;
        }
    }
    .open-editor {
        display: block;
        margin: 20px auto;
    }
}
.button-box {
    margin-top: 20px;
    text-align: right;
}
</style>
