<template>
    <a-modal class="milePop" title="Edit Item Card" :width="600" v-model="visible" ok-text="Confirm" @ok="confirm" @cancel="close" v-if="currentCrad">
        <div class="delete">
            <a-button type="danger" @click="handleDelete"> Delete Card </a-button>
        </div>
        <div class="dividing" style="margin: 25px 0 30px"></div>
        <div class="item">
            <div>Current Item:</div>
            <div class="item-detail">
                <span class="food-name">{{ coverFid ? foods_dict[coverFid].fname : foods_dict[currentCrad.fid].fname }}</span>
                <a-button @click="openFood">Change Item</a-button>
            </div>
        </div>
        <div class="dividing-dashed" style="margin: 25px 0 30px"></div>
        <div>
            <span style="margin-right: 20px">Display Image Frame:</span>
            <a-switch v-model="borderChecked" checked-children="ON" un-checked-children="OFF" />
        </div>
        <div class="dividing-dashed" style="margin: 25px 0 30px"></div>
        <div style="margin-bottom: 20px">Current Image for “{{ coverFid ? foods_dict[coverFid].fname : foods_dict[currentCrad.fid].fname }}”</div>
        <a-upload name="file" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" accept="image/*" reject="uploadReject" :before-upload="(file) => beforeUpload(file)">
            <img class="fullimg" :src="coverImg || currentCrad.img_url || (coverFid ? foods_dict[coverFid].fpic : foods_dict[currentCrad.fid].fpic)" alt="avatar" />
            <!-- <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
            </div> -->
        </a-upload>
        <!-- pop -->
        <food-picker ref="foodPicker" :active="mid" type="edit" @confirm="foodConfirm"></food-picker>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import FoodPicker from '@components/pop/FoodPicker';

export default Vue.extend({
    name: 'CardEdit',
    components: {
        FoodPicker,
    },
    props: {
        mid: {
            type: String,
            default: '',
        },
        index: {
            type: Number,
        },
        fid: {
            type: String,
        },
    },
    data() {
        return {
            visible: false,
            borderChecked: false,
            coverImg: '',
            coverFid: '',
        };
    },
    watch: {
        fid: {
            handler: function () {
                this.borderChecked = this.currentCrad.border == 1;
            },
            immediate: true,
        },
        index: {
            handler: function () {
                this.borderChecked = this.currentCrad.border == 1;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            addedFoodList: 'app/get_added_food_list',
            pageSetting: 'app/get_page_setting',
            sid: 'app/get_sid',
        }),
        ...mapState({
            aj_host: (state) => state.aj_host,
            configure: (state) => state.app.configure,
            foods_dict: (state) => state.food.foods_dict,
        }),
        currentCrad() {
            if (!this.mid || !this.fid) {
                return false;
            }
            return this.pageSetting.page.cards[this.index];
        },
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING',
        }),

        show() {
            this.visible = true;
        },
        openFood() {
            this.$refs.foodPicker.show();
        },
        foodConfirm(e) {
            this.coverFid = e;
            this.coverImg = '';
            this.currentCrad.img_url = '';
        },
        handleDelete() {
            this.$confirm({
                title: 'Warning',
                content: 'Card and the item contained will be removed.',
                okText: 'Delete Card',
                cancelText: 'Cancel',
                onOk: () => {
                    this.visible = false;
                    this.$emit('deletecard');
                },
            });
        },
        uploadReject() {
            this.$notification['warning']({
                message: 'Errot type',
                description: 'Only supports image upload.',
            });
        },
        async beforeUpload(file) {
            if (file.size > 5242880) {
                this.$notification['warning']({
                    message: 'Image size cannot exceed 5MB',
                });
                return false;
            }
            var formData = new FormData();
            formData.append('file', file);
            let time = Date.parse(new Date());
            var randomNum = '';
            for (var i = 0; i < 4; i++) {
                var a = Math.floor(Math.random() * 9);
                randomNum += a;
            }
            formData.append('name', this.sid + '_super_menu_img_' + time + randomNum);
            await this.uploadImg(formData).then((res) => {
                this.coverImg = res.file_url;
            });
            return false;
        },
        uploadImg(params) {
            return new Promise((resolve, reject) => {
                this.setLoading({
                    show_loading: true,
                });
                axiosApi
                    .post(this.aj_host + '/weapp/admin/supermenu/upload', params, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((res) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        resolve(res.data.data.data);
                    })
                    .catch((err) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        reject(err);
                    });
            });
        },
        confirm() {
            this.$emit('confirm', {
                borderChecked: this.borderChecked,
                coverImg: this.coverImg,
                coverFid: this.coverFid,
            });
            this.visible = false;
            this.coverFid = '';
            this.coverImg = '';
        },
        close() {
            this.coverFid = '';
            this.coverImg = '';
        },
    },
});
</script>
<style scoped lang="less">
.delete {
    text-align: right;
}
.item-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .food-name {
        width: 250px;
        word-break: break-all;
    }
}

.avatar-uploader {
    display: block;
    overflow: hidden;
    margin-bottom: 16px;
    width: 210px;
    height: 210px;
}
/deep/ .ant-upload {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    width: 210px !important;
    height: 210px !important;
    cursor: pointer;
}
</style>
