import axiosApi from '@kits/axiosApi';

// initial state
const state = {
    menu: [],
    menu_dict: {},
    foods_dict: {},
};

// getters
const getters = {};

// actions
const actions = {
    initFood({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.aj_host;
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/admin/supermenu/query_teamup_food')
                .then((res) => {
                    if (res.data.data && res.data.data.code == 100000) {
                        let result = res.data.data.data;
                        result.menu = result.menu.filter((item) => item.channel == 'all' || item.channel == 'dine-in');
                        for (let key in result.menu_dict) {
                            if (result.menu_dict[key].channel != 'all' && result.menu_dict[key].channel != 'dine-in') {
                                delete result.menu_dict[key];
                            }
                        }
                        commit('SET_MENU', result.menu);
                        commit('SET_MENU_DICT', result.menu_dict);
                        commit('SET_FOODS', result.foods);
                        resolve(res.data.data.data);
                    } else {
                        reject(res.data.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

// mutations
const mutations = {
    SET_MENU(state, value) {
        state.menu = value;
    },
    SET_MENU_DICT(state, value) {
        state.menu_dict = value;
    },
    SET_FOODS(state, value) {
        state.foods_dict = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
