<template>
    <div class="middle" v-if="JSON.stringify(menu_dict) != '{}'">
        <div class="box">
            <div class="title">Added Categories:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="add" v-if="configure.category_list.length == 0">Add a category from below</div>
            <div class="list">
                <div class="item" v-for="(item, index) in configureWidthPage" :key="item.mid">
                    <div class="row">
                        <span class="name">{{ menu_dict[item.mid].name }}</span>
                        <a-dropdown>
                            <div class="operate"><a-icon class="icon" type="ellipsis" /></div>
                            <a-menu slot="overlay">
                                <a-menu-item v-if="index > 0">
                                    <div class="menu-item" @click="sortCategory(index, 'up')">Move Up</div>
                                </a-menu-item>
                                <a-menu-item v-if="index < configureWidthPage.length - 1">
                                    <div class="menu-item" @click="sortCategory(index, 'down')">Move Down</div>
                                </a-menu-item>
                                <a-menu-item>
                                    <div class="menu-item" @click="openEdit(item.mid)">Edit Category Setting</div>
                                </a-menu-item>
                                <a-menu-item>
                                    <div class="menu-item" style="color: #ff3b30" @click="remove(index)">Remove Category</div>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                    <div class="row" style="margin-top: 4px" v-if="configure.category_layout != 'left'">
                        <span class="info-color">page {{ item.pageStart }}-{{ item.pageEnd }}</span>
                        <span class="view" @click="openPageEdit(item.mid)">view all</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="title">All Categories:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="all-list">
                <div class="item" v-for="item in menu" :key="item.mid" v-if="addedCategory.indexOf(item.mid) == -1">
                    <img class="hide" v-if="item.is_hide == 1" src="../../assets/images/eye-off.png" alt="" />
                    <span class="name">{{ item.name }}</span>
                    <!-- <span class="view">view</span> -->
                    <a-icon class="add-icon" type="plus-circle" theme="filled" @click="openEdit(item.mid)" />
                </div>
            </div>
        </div>
        <!-- pop -->
        <category-view></category-view>
        <category-setting ref="setting" :active="activeMid" @mange="mangePages"></category-setting>
        <category-setting-left ref="setting-left" :active="activeMid" @clear-active="clearActive"></category-setting-left>
        <category-page ref="page" :active="activeMid"></category-page>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import CategoryView from '@components/pop/CategoryView';
import CategorySetting from '@components/pop/CategorySetting';
import CategorySettingLeft from '@components/pop/CategorySettingLeft';
import CategoryPage from '@components/pop/CategoryPage';

export default Vue.extend({
    name: 'EtitorProducts',
    components: {
        CategoryView,
        CategorySetting,
        CategorySettingLeft,
        CategoryPage,
    },
    props: {},
    data() {
        return {
            aj_host_admin: this.$store.state.aj_host_admin,
            activeMid: '',
        };
    },
    watch: {},
    async created() {
        await this.initFood();
        this.cleanCateAndCrad();
    },
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
            menu: (state) => state.food.menu,
            menu_dict: (state) => state.food.menu_dict,
        }),
        addedCategory() {
            return this.configure.category_list.map((item) => item.mid);
        },
        configureWidthPage() {
            let configure = this._.cloneDeep(this.configure);

            let count = 1;
            return configure.category_list.map((item) => {
                if (item.page_list.length == 0) {
                    item.pageStart = 0;
                    item.pageEnd = 0;
                } else {
                    item.pageStart = count;
                    item.pageEnd = count + item.page_list.length - 1;
                    count += item.page_list.length;
                }
                return item;
            });
        },
    },
    methods: {
        ...mapActions({
            initFood: 'food/initFood',
            cleanCateAndCrad: 'app/cleanCateAndCrad',
        }),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
            set_page: 'app/SET_PAGE',
            set_page_left: 'app/SET_PAGE_LEFT',
        }),

        openEdit(mid) {
            this.activeMid = mid;
            if (this.configure.category_layout == 'left') {
                this.$refs['setting-left'].show();
            } else {
                this.$refs.setting.show();
            }
        },
        openPageEdit(mid) {
            this.activeMid = mid;
            this.$refs.page.show();
        },
        mangePages(e) {
            this.openPageEdit(e);
        },
        clearActive() {
            this.activeMid = '';
        },
        remove(index) {
            this.$confirm({
                title: 'Warning',
                content: 'All pages and items from this category will be removed.',
                okText: 'Remove',
                cancelText: 'Cancel',
                onOk: () => {
                    let configure = this._.cloneDeep(this.configure);
                    configure.category_list.splice(index, 1);
                    this.set_configure(configure);
                    this.set_page(1);
                    this.set_page_left(0);
                },
            });
        },
        sortCategory(index, type) {
            let increase = type == 'up' ? -1 : 1;
            let configure = this._.cloneDeep(this.configure);
            configure.category_list[index] = configure.category_list.splice(index + increase, 1, configure.category_list[index])[0];
            this.set_configure(configure);
        },
    },
});
</script>
<style scoped lang="less">
.middle {
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 30px 20px;
    width: 370px;
    background-color: #d6d6d6;
    .box {
        box-sizing: border-box;
        margin-bottom: 24px;
        padding: 20px 25px;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        .title {
            font-weight: bold;
            font-size: 20px;
        }
        .subhead {
            font-weight: 600;
        }
    }
    .add {
        margin-top: 19px;
        color: @main-color;
        font-size: 16px;
        cursor: pointer;
    }
    .list {
        .item {
            margin-bottom: 18px;
            padding-bottom: 11px;
            border-bottom: 1px dashed #979797;
            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .operate {
                    color: #d8d8d8;
                    font-size: 26px;
                    cursor: pointer;
                }
                .name {
                    font-weight: 500;
                    font-size: 18px;
                }
                .view {
                    color: @main-color;
                    cursor: pointer;
                }
            }
        }
    }
    .all-list {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 18px;
            .hide {
                margin-right: 6px;
                width: 20px;
                height: 20px;
            }
            .name {
                margin-right: auto;
                font-weight: 500;
                font-size: 18px;
            }
            .view {
                margin-left: auto;
                color: @info-color;
                text-decoration: underline;
                cursor: pointer;
            }
            .add-icon {
                margin-left: 23px;
                color: @main-color;
                font-size: 22px;
                cursor: pointer;
            }
        }
    }
}
</style>
