<template>
    <div class="layout-thumbnail">
        <!-- 11 -->
        <div class="frame vertical" v-if="type == 11">
            <div class="row">
                <div class="thumbnail-xl">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
            </div>
        </div>
        <!-- 12 -->
        <div class="frame horizontal" v-if="type == 12">
            <div class="row">
                <div class="thumbnail-xl">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
            </div>
        </div>
        <!-- 21 -->
        <div class="frame vertical" v-if="type == 21">
            <div class="row">
                <div class="thumbnail-l">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
        </div>
        <!-- 22 -->
        <div class="frame horizontal" v-if="type == 22">
            <div class="row">
                <div class="thumbnail-l">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
        </div>
        <!-- 31 -->
        <div class="frame vertical" v-if="type == 31">
            <div class="row">
                <div class="thumbnail-l">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
            </div>
        </div>
        <!-- 32 -->
        <div class="frame horizontal" style="flex-wrap: nowrap" v-if="type == 32">
            <div class="row">
                <div class="thumbnail-l">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
            </div>
            <div class="row" style="flex-direction: column; justify-content: space-around; height: 100%">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
            </div>
        </div>
        <!-- 41 -->
        <div class="frame vertical" v-if="type == 41">
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
            </div>
        </div>
        <!-- 42 -->
        <div class="frame vertical" v-if="type == 42">
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
            </div>
        </div>
        <!-- 43 -->
        <div class="frame horizontal" v-if="type == 43">
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
            </div>
        </div>
        <!-- 51 -->
        <div class="frame vertical" v-if="type == 51">
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[4]].fpic" v-if="foods[4]" />
                </div>
            </div>
        </div>
        <!-- 52 -->
        <div class="frame horizontal" v-if="type == 52">
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
                <div class="thumbnail-m">
                    <img class="fullimg" alt="" :src="foods_dict[foods[4]].fpic" v-if="foods[4]" />
                </div>
                <div class="thumbnail-s blank"></div>
            </div>
        </div>
        <!-- 61 -->
        <div class="frame vertical" v-if="type == 61">
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[4]].fpic" v-if="foods[4]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[5]].fpic" v-if="foods[5]" />
                </div>
            </div>
        </div>
        <!-- 62 -->
        <div class="frame horizontal" v-if="type == 62">
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[4]].fpic" v-if="foods[4]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[5]].fpic" v-if="foods[5]" />
                </div>
            </div>
        </div>
        <!-- 81 -->
        <div class="frame vertical" v-if="type == 81">
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[4]].fpic" v-if="foods[4]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[5]].fpic" v-if="foods[5]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[6]].fpic" v-if="foods[6]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[7]].fpic" v-if="foods[7]" />
                </div>
            </div>
        </div>
        <!-- 82 -->
        <div class="frame horizontal" v-if="type == 82">
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[0]].fpic" v-if="foods[0]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[1]].fpic" v-if="foods[1]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[2]].fpic" v-if="foods[2]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[3]].fpic" v-if="foods[3]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[4]].fpic" v-if="foods[4]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[5]].fpic" v-if="foods[5]" />
                </div>
            </div>
            <div class="row">
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[6]].fpic" v-if="foods[6]" />
                </div>
                <div class="thumbnail-s">
                    <img class="fullimg" alt="" :src="foods_dict[foods[7]].fpic" v-if="foods[7]" />
                </div>
                <div class="thumbnail-s blank"></div>
            </div>
        </div>
        <a-button class="button-confirm" type="primary" size="large" @click="confirm" v-show="buttonVisible">Add this layout</a-button>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'ColorPalette',
    props: {
        type: {
            type: Number,
            default: 11,
        },
        buttonVisible: {
            type: Boolean,
            default: false,
        },
        foods: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            foods_dict: (state) => state.food.foods_dict,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        confirm() {
            this.$emit('confirm', this.type);
        },
    },
});
</script>
<style scoped lang="less">
.layout-thumbnail {
    position: relative;
    width: 24em !important;
    height: 28em;

    .button-confirm {
        position: absolute;
        bottom: 0;
    }
    .frame {
        margin: 0 auto;
        margin-bottom: 1em;
        padding: 1em;
        background-color: #d8d8d8;
        &.vertical {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 18em;
            height: 24em;
            .row {
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: space-around;
                width: 100%;
            }
        }
        &.horizontal {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 24em;
            height: 18em;
            .row {
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: space-around;
            }
        }
    }

    .thumbnail-s {
        width: 5em;
        height: 3em;
        background-color: #ffffff;
    }
    .thumbnail-m {
        width: 7em;
        height: 5em;
        background-color: #ffffff;
    }
    .thumbnail-l {
        width: 9em;
        height: 9rem;
        background-color: #ffffff;
    }
    .thumbnail-xl {
        width: 14em;
        height: 16em;
        background-color: #ffffff;
    }
    .blank {
        background-color: #d8d8d8;
    }
}
</style>
