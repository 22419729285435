<template>
    <div class="middle">
        <div class="box">
            <div class="title">Page Background:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="img-wrap">
                <div class="fullimg" :style="bgClass"></div>
                <a-button class="change" type="primary" @click="changeImg" size="large" :disabled="configure.image_all_enable">Change</a-button>
            </div>

            <div :class="['apply-all', configure.image_all_enable ? 'disabled' : '']" @click="applyAll">apply to all pages</div>
        </div>
        <div class="box">
            <div class="title">Add an item card:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="card-list">
                <div class="card">
                    <div class="card-texture">super</div>
                    <a-button class="add-button" @click="addCard('card4')">add</a-button>
                </div>
                <div class="card">
                    <div class="card-texture" style="height: 95px">large</div>
                    <a-button class="add-button" @click="addCard('card3')">add</a-button>
                </div>
                <div class="card">
                    <div class="card-texture" style="width: 71px; height: 82px">medium</div>
                    <a-button class="add-button" @click="addCard('card2')">add</a-button>
                </div>
                <div class="card">
                    <div class="card-texture" style="width: 51px; height: 56px">small</div>
                    <a-button class="add-button" @click="addCard('card1')">add</a-button>
                </div>
                <div class="card">
                    <div class="card-texture" style="width: 117px; height: 56px">no image</div>
                    <a-button class="add-button" @click="addCard('card51')">add</a-button>
                </div>
                <div class="card">
                    <div class="card-texture" style="width: 225px; height: 60px">text</div>
                    <a-button class="add-button" @click="addCard('card61')">add</a-button>
                </div>
            </div>
        </div>
        <!-- pop -->
        <card-layout-choose ref="cardLayoutChoose" @confirm="cardConfirm" :size="cardType"></card-layout-choose>
        <edit-background ref="background" :active="pageSetting.page.background" @confirm="backgroundConfirm"></edit-background>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import CardLayoutChoose from '@components/pop/CardLayoutChoose';
import EditBackground from '@components/pop/EditBackground';

export default Vue.extend({
    name: 'EtitorPage',
    components: {
        CardLayoutChoose,
        EditBackground,
    },
    props: {},
    data() {
        return {
            cardType: '',
            bgClass: {},
        };
    },
    watch: {
        'pageSetting.page.background': {
            handler(newValue, oldValue) {
                if (!newValue || newValue.indexOf('#') == 0) {
                    this.bgClass = {
                        backgroundColor: newValue || this.configure.color_palette.color3,
                    };
                } else {
                    this.bgClass = {
                        background: `url(${newValue})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    };
                }
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            pageSetting: 'app/get_page_setting',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            page: (state) => state.app.page,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
        }),
        addCard(type) {
            if (type == 'card4') {
                if (this.configure.direction == 'vertical') {
                    this.insertCard(type + '2');
                } else {
                    this.insertCard(type + '1');
                }
                return;
            }
            if (type == 'card61' || type == 'card51') {
                this.insertCard(type);
                return;
            }
            this.cardType = type;
            this.$refs.cardLayoutChoose.show();
        },
        cardConfirm(type) {
            let cardType = this.cardType + type;
            this.insertCard(cardType);
        },
        insertCard(type) {
            let configure = this._.cloneDeep(this.configure);
            if (configure.category_list.length == 0) {
                this.$notification['warning']({
                    message: 'Please add a category first',
                });
            }
            let count = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    if (count == this.page - 1) {
                        if (type == 'card61') {
                            page.cards.push({
                                x: 0, //坐标x
                                y: 0, //坐标y
                                type: type,
                                text_color: '#000000', //设置文字颜色
                                text: 'Click Here to Edit',
                                height: 100,
                            });
                        } else {
                            page.cards.push({
                                x: 0, //坐标x
                                y: 0, //坐标y
                                type: type,
                                img_url: '', //替换菜品的默认图片
                                border: this.configure.frame_display ? 1 : 0, // 0/1
                                fid: '',
                            });
                        }
                    }
                    count++;
                }
            }
            this.set_configure(configure);
        },
        changeImg() {
            let configure = this._.cloneDeep(this.configure);
            if (configure.category_list.length == 0) {
                this.$notification['warning']({
                    message: 'Please add a category first',
                });
                return;
            }
            this.$refs.background.show();
        },
        backgroundConfirm(e) {
            let configure = this._.cloneDeep(this.configure);
            let count = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    if (count == this.page - 1) {
                        page.background = e;
                    }
                    count++;
                }
            }
            this.set_configure(configure);
        },
        applyAll() {
            if (this.configure.image_all_enable) {
                return;
            }
            this.$confirm({
                title: 'Confirm',
                content: 'Apply background image to all pages?',
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: () => {
                    let configure = this._.cloneDeep(this.configure);
                    for (let i = 0; i < configure.category_list.length; i++) {
                        const cate = configure.category_list[i];
                        for (let j = 0; j < cate.page_list.length; j++) {
                            const page = cate.page_list[j];
                            page.background = this.pageSetting.page.background;
                        }
                    }
                    this.set_configure(configure);
                    this.$notification['success']({
                        message: 'Success',
                        description: 'Successfully!',
                    });
                },
            });
        },
    },
});
</script>
<style scoped lang="less">
.middle {
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 30px 20px;
    width: 370px;
    background-color: #d6d6d6;
    .img-wrap {
        position: relative;
        width: 100%;
        height: 150px;
        .change {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 150px;
        }
    }
    .box {
        box-sizing: border-box;
        margin-bottom: 24px;
        padding: 20px 25px;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        .title {
            font-weight: bold;
            font-size: 20px;
        }
        .subhead {
            font-weight: 600;
        }
    }
    /deep/ .ant-upload {
        width: 100%;
    }
    .apply-all {
        margin-top: 17px;
        color: @info-color;
        text-decoration: underline;
        cursor: pointer;
        &.disabled {
            cursor: not-allowed;
        }
    }
    .card-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: 36px;
            min-width: 130px;
            .add-button {
                margin-top: 18px;
            }
            .card-texture {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 102px;
                height: 129px;
                border: 1px solid #979797;
                background-color: #d8d8d8;
            }
        }
    }
}
</style>
