<template>
    <a-modal class="milePop" :title="menu_dict[active].name" :width="1200" v-model="visible" @cancel="onCloseModal" ok-text="Confirm" @ok="confirm" v-if="active">
        <div class="flex-box">
            <div class="left">
                <div class="count">
                    {{ menu_dict[active].list.length }} items
                    <a-button type="primary" style="margin-right: 10px" @click="addAll"> add all </a-button>
                </div>
                <div class="dividing" style="margin: 20px 0 15px"></div>
                <div class="flex-box">
                    <div class="list-box" style="border-right: 1px solid #e5e5e5">
                        <div style="font-size: 18px; margin-bottom: 20px">All products</div>
                        <div class="list">
                            <div class="item" v-for="item in menu_dict[active].list" :key="item.fid">
                                <div class="name">{{ item.fname }}</div>
                                <div class="added" v-show="pickFoodList.indexOf(item.fid) > -1">added</div>
                                <a-icon
                                    class="add-icon active"
                                    type="plus-circle"
                                    theme="filled"
                                    v-show="pickFoodList.indexOf(item.fid) == -1 && pickFoodList.indexOf(item.fid) == -1"
                                    @click="add(item)"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="list-box" style="padding-left: 10px">
                        <div style="font-size: 18px; margin-bottom: 20px">Added</div>
                        <div class="list">
                            <div class="item" v-for="(fid, index) in pickFoodList" :key="fid">
                                <div class="name">
                                    <a-icon class="sort-icon up" type="up-circle" @click="sort(index, 'up')" />
                                    <a-icon class="sort-icon down" type="down-circle" @click="sort(index, 'down')" />
                                    {{ foods_dict[fid].fname }}
                                </div>
                                <div class="remove" @click="remove(fid)">remove</div>
                            </div>
                        </div>
                        <div class="sort" @click="resetOrder">reset to default order</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="info">Layout Style</div>
                <div class="rows" style="margin: 30px 0 15px">
                    Number of item per row:
                    <a-select v-model="rows" style="width: 100px; margin-left: 10px">
                        <a-select-option :value="2"> 2 </a-select-option>
                        <a-select-option :value="3"> 3 </a-select-option>
                        <a-select-option :value="4"> 4 </a-select-option>
                        <a-select-option :value="5"> 5 </a-select-option>
                    </a-select>
                </div>
                <div>
                    Display item image
                    <a-switch v-model="image_display" />
                </div>
                <div class="preview">preview</div>
                <layout-thumbnail-left class="thumbnail" :rows="rows" :image="image_display"></layout-thumbnail-left>
            </div>
        </div>
    </a-modal>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import LayoutThumbnailLeft from '@components/layout/LayoutThumbnailLeft';

export default Vue.extend({
    name: 'CategorySetting',
    components: {
        LayoutThumbnailLeft,
    },
    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: false,
            rows: 2,
            image_display: true,
            pickFoodList: [],
        };
    },
    watch: {
        active: {
            handler: function (val, oldVal) {
                if (!val) {
                    return;
                }
                this.pickFoodList = val ? this.addedFoodList(val) : [];

                let categorySetting = this.categorySetting(val);
                this.rows = categorySetting ? categorySetting.rows : 2;
                this.image_display = categorySetting ? categorySetting.image_display : true;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            addedFoodList: 'app/get_added_food_list',
            categorySetting: 'app/get_category_setting',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            menu_dict: (state) => state.food.menu_dict,
            foods_dict: (state) => state.food.foods_dict,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
            set_page_left: 'app/SET_PAGE_LEFT',
        }),

        show() {
            this.visible = true;
        },
        resetOrder() {
            let fids = this.menu_dict[this.active].fids;
            this.pickFoodList.sort((a, b) => {
                let aIndex = fids.indexOf(a);
                let bIndex = fids.indexOf(b);
                if (aIndex === -1) {
                    aIndex = 99999;
                }
                if (bIndex === -1) {
                    bIndex = 99999;
                }
                return aIndex - bIndex;
            });
        },
        add(food) {
            this.pickFoodList.push(food.fid);
        },
        addAll() {
            for (let i = 0; i < this.menu_dict[this.active].list.length; i++) {
                const food = this.menu_dict[this.active].list[i];
                if (this.pickFoodList.indexOf(food.fid) == -1) {
                    this.pickFoodList.push(food.fid);
                }
            }
        },
        remove(fid) {
            for (let i = 0; i < this.pickFoodList.length; i++) {
                if (fid == this.pickFoodList[i]) {
                    this.pickFoodList.splice(i, 1);
                    break;
                }
            }
        },
        sort(index, type) {
            if (index == 0 && type == 'up') {
                return;
            }
            if (index == this.pickFoodList.length - 1 && type == 'down') {
                return;
            }
            let increase = type == 'up' ? -1 : 1;
            this.pickFoodList[index] = this.pickFoodList.splice(index + increase, 1, this.pickFoodList[index])[0];
        },
        onCloseModal() {
            this.pickFoodList = [];
            this.$emit('clear-active');
        },
        confirm() {
            if (this.pickFoodList.length == 0) {
                this.$notification['warning']({
                    message: 'Please add Food',
                });
                return;
            }
            let category = {
                mid: this.active,
                rows: this.rows,
                image_display: this.image_display,
                page_list: [],
            };
            let page = {
                background: '',
                cards: [],
            };
            for (let i = 0; i < this.pickFoodList.length; i++) {
                let fid = this.pickFoodList[i];
                let card = {
                    x: 0,
                    y: 0,
                    type: 'card11',
                    img_url: '',
                    border: 1,
                    fid: fid,
                };
                page.cards.push(card);
            }
            category.page_list = [page];

            let configure = this._.cloneDeep(this.configure);
            let midExist = false;
            let page_left = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                if (cate.mid == this.active) {
                    midExist = true;
                    cate.rows = this.rows;
                    cate.image_display = this.image_display;
                    cate.page_list = [page];
                    page_left = i;
                    break;
                }
            }
            if (!midExist) {
                configure.category_list.push(category);
                page_left = configure.category_list.length - 1;
            }
            this.set_configure(configure);
            this.onCloseModal();
            this.set_page_left(page_left);
            this.visible = false;
        },
    },
});
</script>
<style scoped lang="less">
.flex-box {
    display: flex;
    .left {
        flex-basis: 60%;
        flex-shrink: 0;
        border-right: 1px solid #e5e5e5;
        .count {
            display: flex;
            justify-content: space-between;
        }
        .list-box {
            flex-basis: 50%;
            .sort {
                padding-top: 15px;
                padding-right: 10px;
                border-top: 1px solid #e5e5e5;
                color: @main-color;
                text-align: right;
                text-decoration: underline;
                cursor: pointer;
            }
            .list {
                overflow-y: auto;
                padding-right: 10px;
                height: 600px;
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    font-size: 16px;
                    .sort-icon {
                        color: @main-color;
                        &.up {
                            margin-right: 5px;
                        }
                        &.down {
                            margin-right: 10px;
                        }
                    }
                    .name {
                        max-width: 200px;
                    }
                    .add-icon {
                        color: @info-color;
                        font-size: 20px;
                        cursor: pointer;
                        &.active {
                            color: @main-color;
                        }
                    }
                    .remove {
                        color: @main-color;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .added {
                        color: @info-color;
                    }
                }
            }
        }
    }
    .right {
        padding-left: 50px;
        .info {
            font-size: 16px;
        }
        .thumbnail {
            font-size: 14px;
        }
        .preview {
            margin: 30px 0 10px;
            color: @main-color;
            font-size: 16px;
        }
    }
}
</style>
