import axiosApi from '@kits/axiosApi';
import _ from 'lodash';
import { THEME_LIST } from '@/utils/theme';

// initial state
const state = {
    sid: window.localStorage.getItem('sid') || '',
    timezone: window.localStorage.getItem('timezone') || '',
    tap_to_order: true, //tap_to_order显示
    show_loading: false,
    page: 1,
    nav_index: 1,
    editable: 1, //1:可编辑 2:预览 不可返回编辑 3:预览 可返回编辑
    color_palette: [],
    component_view: '',
    page_left: 0, //left模式下,当前类目的index
    operation_history: [], //拖曳操作历史
    configure: {
        // theme_name: 'classic',
        // border_img: '', //图片边框图片
        // quantity_border_img: '', //数量选择边框图片
        // quantity_bg_img: '', //数量选择底色背景图片
        // category_img: '', //类目选择边框图片
        // color_palette: { color1: '#da4453', color2: '#2e2242', color3: '#112ade' },
        // font_family: { store_name: 'Oriya MN', category_name: 'Oriya MN', item_name: 'Oriya MN', quantity: 'Oriya MN', price: 'Oriya MN', paragraph: 'Oriya MN', cover: 'Oriya MN' },
        // item_name_color: '#FE0000',
        // direction: 'vertical',
        // category_layout: 'top',
        // background_cover: 'https://d3puj4wh202ha4.cloudfront.net/s7bd0b76d23a6c509524389ea31b84e33_super_menu_img_16699620680001863.png',
        // category_list: [
        //     {
        //         mid: 'm449b634b4fedbab3c9e76ef076299c82',
        //         rows: 4,
        //         image_display: true,
        //         page_list: [
        //             {
        //                 background: 'https://d3puj4wh202ha4.cloudfront.net/s7bd0b76d23a6c509524389ea31b84e33_super_menu_img_16699620680001863.png',
        //                 cards: [
        //                     {
        //                         x: 350, //坐标x
        //                         y: 10, //坐标y
        //                         type: 'card11',
        //                         img_url: '', //替换菜品的默认图片
        //                         border: 1, // 0/1
        //                         fid: '',
        //                     },
        //                 ],
        //             },
        //         ],
        //     },
        //     {
        //         mid: 'm73c74bffe793200f539ae578659ae8c2',
        //         rows: 4,
        //         image_display: true,
        //         page_list: [
        //             {
        //                 background: '',
        //                 cards: [
        //                     { x: 300, y: 0, type: 'card12', img_url: '', border: 1, fid: 'ff735456726c2f6963a9977864d55479e' },
        //                     { x: 0, y: 300, type: 'card12', img_url: '', border: 1, fid: 'fa312fde3daa0e00eef7170e3b5a68251' },
        //                 ],
        //             },
        //         ],
        //     },
        // ],
        // navigation_color: 'filled',
        // category_color: 'filled',
        // call_server_display: 1,
        // frame_display: true,
        // image_all: '',
        // image_all_enable: false,
        // item_detail_popup_style: 'small',
        // dark_mode_enable: false,
    },
};

// getters
const getters = {
    get_sid(state) {
        return state.sid;
    },
    get_timezone(state) {
        return state.timezone;
    },
    get_loading(state) {
        return state.show_loading;
    },
    get_configure(state) {
        return state.configure;
    },
    get_operation_history(state) {
        return state.operation_history;
    },
    get_color_palette(state) {
        let list = [];
        for (let key in THEME_LIST) {
            list.push(THEME_LIST[key].color_palette);
        }
        list.push({
            color1: '#F1DDB2',
            color2: '#52362E',
            color3: '#B43257',
        });
        return list;
    },

    // 获取当前类目下已添加的food的fid
    get_added_food_list(state) {
        return (mid) => {
            let list = [];
            for (let i = 0; i < state.configure.category_list.length; i++) {
                const item = state.configure.category_list[i];
                if (item.mid == mid) {
                    for (let j = 0; j < item.page_list.length; j++) {
                        const page = item.page_list[j];
                        for (let k = 0; k < page.cards.length; k++) {
                            const card = page.cards[k];
                            list.push(card.fid);
                        }
                    }
                    return list;
                }
            }
            return list;
        };
    },
    // 获取总页数
    get_page_total(state) {
        let count = 0;
        for (let i = 0; i < state.configure.category_list.length; i++) {
            const cate = state.configure.category_list[i];
            count += cate.page_list.length;
        }
        return count;
    },
    // 获取当前页的page_list及mid,可用于preview渲染
    get_page_setting(state) {
        let count = 0;
        for (let i = 0; i < state.configure.category_list.length; i++) {
            const cate = state.configure.category_list[i];
            for (let j = 0; j < cate.page_list.length; j++) {
                const page = cate.page_list[j];
                if (count == state.page - 1) {
                    return {
                        mid: cate.mid,
                        page,
                    };
                }
                count++;
            }
        }
        return {
            mid: '',
            page: {
                background: state.configure.background_cover,
                cards: [
                    {
                        x: 130, //坐标x
                        y: 10, //坐标y
                        type: 'card32',
                        img_url: require('@assets/images/food.png'), //替换菜品的默认图片
                        border: state.configure.frame_display ? 1 : 0, // 0/1
                        fid: '',
                        disabled: true,
                    },
                    {
                        x: 40, //坐标x
                        y: 590, //坐标y
                        type: 'card11',
                        img_url: require('@assets/images/food.png'), //替换菜品的默认图片
                        border: state.configure.frame_display ? 1 : 0, // 0/1
                        fid: '',
                        disabled: true,
                    },
                    {
                        x: 450, //坐标x
                        y: 590, //坐标y
                        type: 'card11',
                        img_url: require('@assets/images/food.png'), //替换菜品的默认图片
                        border: state.configure.frame_display ? 1 : 0, // 0/1
                        fid: '',
                        disabled: true,
                    },
                ],
            },
        };
    },
    // 根据mid获取 当前类目信息 带全局页码
    get_category_setting(state) {
        return (mid) => {
            let count = 0;
            let configure = _.cloneDeep(state.configure);
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    page.pageNo = count;
                    count++;
                }
            }

            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                if (cate.mid == mid) {
                    return cate;
                }
            }
        };
    },
    // 获取新添加page的页码 当前类目下的最后一页
    get_page_insert(state) {
        return (mid) => {
            let count = 0;
            for (let i = 0; i < state.configure.category_list.length; i++) {
                const cate = state.configure.category_list[i];
                count += cate.page_list.length;
                if (cate.mid == mid) {
                    return count;
                }
            }
        };
    },
    // left模式下,获取当前类目的page_list,可用于preview渲染
    get_page_setting_left(state) {
        if (state.configure.category_list.length == 0) {
            return false;
        }
        for (let i = 0; i < state.configure.category_list.length; i++) {
            const cate = state.configure.category_list[i];
            if (i == state.page_left) {
                return {
                    mid: cate.mid,
                    rows: cate.rows,
                    image_display: cate.image_display,
                    page: cate.page_list.length > 0 ? cate.page_list[0] : false,
                };
            }
        }
    },
};

// actions
const actions = {
    initStoreData({ state, commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.aj_host;
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/admin/supermenu/store_data', { params: post_data })
                .then((res) => {
                    if (res.data.data && res.data.data.code == 100000) {
                        let store = res.data.data.data;
                        window.localStorage.setItem('sid', store.sid);
                        window.localStorage.setItem('slogo', store.slogo);
                        window.localStorage.setItem('timezone', store.timezone || 'America/New_York');
                        commit('APP_SET_GLOBAL_DAT', store);
                        resolve(res.data.data);
                    } else {
                        resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    // 清除已添加的 失效类目和卡片(删除,更改channel等)
    cleanCateAndCrad({ state, commit, rootState, rootGetters }) {
        let foodDict = _.cloneDeep(rootState.food.foods_dict);
        let menu_dict = _.cloneDeep(rootState.food.menu_dict);
        let configure = _.cloneDeep(state.configure);
        for (let i = 0; i < configure.category_list.length; i++) {
            const cate = configure.category_list[i];
            if (!menu_dict[cate.mid]) {
                configure.category_list.splice(i, 1);
                i--;
                continue;
            }
            for (let j = 0; j < cate.page_list.length; j++) {
                const page = cate.page_list[j];
                for (let k = 0; k < page.cards.length; k++) {
                    const card = page.cards[k];
                    if (card.type != 'card61' && !foodDict[card.fid]) {
                        page.cards.splice(k, 1);
                        k--;
                    }
                }
            }
        }
        commit('SET_CONFIGURE', configure);
    },
    // 设置所有图片frame的显示状态
    resetFrameDisplay({ state, commit, rootState, rootGetters }, value) {
        let configure = _.cloneDeep(state.configure);
        for (let i = 0; i < configure.category_list.length; i++) {
            const cate = configure.category_list[i];
            for (let j = 0; j < cate.page_list.length; j++) {
                const page = cate.page_list[j];
                for (let k = 0; k < page.cards.length; k++) {
                    const card = page.cards[k];
                    card.border = value ? 1 : 0;
                }
            }
        }
        commit('SET_CONFIGURE', configure);
    },
    // 清除所有类目和food
    emptyCategory({ state, commit, rootState, rootGetters }) {
        let configure = _.cloneDeep(state.configure);
        configure.category_list = [];
        commit('SET_CONFIGURE', configure);
    },
    // 切换到lefft布局,page_list只留一条数据,过滤没有fid的card
    switchLayoutLeft({ state, commit, rootState, rootGetters }) {
        let configure = _.cloneDeep(state.configure);
        for (let i = 0; i < configure.category_list.length; i++) {
            const cate = configure.category_list[i];
            cate.rows = 2;
            cate.image_display = true;
            let cards = [];
            for (let j = 0; j < cate.page_list.length; j++) {
                const page = cate.page_list[j];
                for (let k = 0; k < page.cards.length; k++) {
                    const card = page.cards[k];
                    if (card.fid) {
                        cards.push(card);
                    }
                }
                if (j > 0) {
                    cate.page_list.splice(j, 1);
                    j--;
                }
            }
            cate.page_list[0].cards = cards;
        }
        commit('SET_CONFIGURE', configure);
    },
    // 回撤拖曳操作
    undo({ state, commit, rootState, rootGetters }) {
        let configure = state.operation_history[0];
        commit('SET_CONFIGURE', configure);
    },
};

// mutations
const mutations = {
    APP_SET_LOADING(state, value) {
        state.show_loading = value.show_loading;
    },
    SET_CONFIGURE(state, value) {
        state.configure = value;
    },
    SET_PAGE(state, value) {
        state.page = value;
    },
    SET_NAV_INDEX(state, value) {
        state.nav_index = value;
    },
    SET_COMPONENT_VIEW(state, value) {
        state.component_view = value;
    },
    SET_EDITABLE(state, value) {
        state.editable = value;
    },
    SET_COLOR_PALETTE(state, value) {
        state.color_palette = value;
    },
    APP_SET_GLOBAL_DAT(state, _data) {
        state.sid = _data.sid;
        state.timezone = _data.timezone;
    },
    SET_TAP_TO_ORDER(state, value) {
        state.tap_to_order = value;
    },
    SET_PAGE_LEFT(state, value) {
        state.page_left = value;
    },
    ADD_OPERATION_HISTORY(state, value) {
        let history = _.cloneDeep(state.operation_history);
        let item = _.cloneDeep(value);
        if (history.length > 10) {
            history.shift();
        }
        history.push(item);
        state.operation_history = history;
    },
    CLAEN_OPERATION_HISTORY(state) {
        state.operation_history = [];
    },
    POP_OPERATION_HISTORY(state) {
        let history = _.cloneDeep(state.operation_history);
        history.pop();
        state.operation_history = history;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
