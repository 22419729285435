<template>
    <div class="cate">
        <div class="list">
            <div class="item" :style="{ backgroundColor: configure.color_palette.color3, color: '#fff', fontWeight: 'bold' }">Category</div>
            <div class="item" v-for="item in 15" :key="item">Category</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'CatePreview',
    components: {},
    props: {},
    data() {
        return {
            bgClass: {},
        };
    },
    watch: {
        configure: {
            handler: function (newValue) {
                this.bgClass = {
                    background: newValue.background_cover.indexOf('#') == 0 ? newValue.background_cover : `url(${newValue.background_cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
    },
});
</script>
<style scoped lang="less">
.cate {
    position: relative;
    overflow-y: auto;
    align-items: center;
    width: 200px;
    height: 100%;
    background-color: #2c2c2e;
    font-size: 16px;
    &::-webkit-scrollbar {
        display: none;
    }
    .list {
        .item {
            height: 60px;
            color: #f2f2f7;
            text-align: center;
            line-height: 60px;
            cursor: pointer;
            .cate-icon {
                font-size: 24px;
            }
        }
    }
}
</style>
