<template lang="html">
    <div id="app" v-resize="handleResize">
        <router-view v-if="isRouterAlive"></router-view>
        <div class="loading-layer" v-show="show_loading">
            <a-spin size="large"></a-spin>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    name: 'app',
    components: {},
    data() {
        return {
            isRouterAlive: true,
        };
    },
    provide() {
        return {
            reload: this.reload,
        };
    },
    beforeMount() {},
    updated() {},
    beforeCreate() {},
    created() {},
    mounted() {},
    watch: {},
    computed: {
        ...mapGetters({
            show_loading: 'app/get_loading',
        }),
        ...mapState({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        handleResize({ width, height }) {
            window.parent.postMessage(height, '*');
        },
    },
};
</script>

<style>
.loading-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #eee;
    opacity: 0.7;
}
</style>
