<template>
    <a-modal class="milePop" title="What layout would you like?" :width="900" v-model="visible" ok-text="Confirm" @ok="confirm">
        <div class="layout-box">
            <div :class="['item', selected == 'top' ? 'active' : '']" @click="selected = 'top'">
                <div class="img-box">
                    <img class="theme-img" :src="layout1" alt="" />
                </div>
                <div class="selected">selected</div>
            </div>
            <div :class="['item', selected == 'bottom' ? 'active' : '']" @click="selected = 'bottom'">
                <div class="img-box">
                    <img class="theme-img" :src="layout2" alt="" />
                </div>
                <div class="selected">selected</div>
            </div>
            <div :class="['item', selected == 'left' ? 'active' : '']" @click="selected = 'left'" v-show="configure.direction == 'horizontal'">
                <div class="img-box">
                    <img class="theme-img" :src="layout3" alt="" />
                </div>
                <div class="selected">selected</div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'LayoutChoose',
    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            layout1: require('@assets/images/layout1.png'),
            layout2: require('@assets/images/layout2.png'),
            layout3: require('@assets/images/layout3.png'),
            visible: false,
            selected: '',
        };
    },
    watch: {
        active: {
            handler(newValue, oldValue) {
                this.selected = newValue;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        confirm() {
            this.visible = false;
            this.$emit('confirm', this.selected);
        },
    },
});
</script>
<style scoped lang="less">
.layout-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px;
    .item {
        text-align: center;
        cursor: pointer;
    }
    .selected {
        display: none;
        margin-top: 16px;
        color: @main-color;
        font-weight: bold;
        font-size: 22px;
    }
    .img-box {
        padding: 20px;
        width: 422px;
        height: 218px;
        border: 2px solid #fff;
        border-radius: 15px;
    }
    .item img {
        width: 100%;
        height: 100%;
    }
    .active .selected {
        display: block;
    }
    .active .img-box {
        border-color: @main-color;
    }
}
</style>
