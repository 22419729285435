import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import Index from './views/SuperMenu/Index';
import Editor from './views/SuperMenu/Editor';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/admin/index', component: Index },
        { path: '/admin/editor', component: Editor },
    ],
});

router.beforeEach(async (to, from, next) => {
    store.commit('app/APP_SET_LOADING', { show_loading: false });
    next();
});

export default router;
