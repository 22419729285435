<template>
    <div class="navigation" :style="{ backgroundColor: configure.navigation_color == 'filled' ? configure.color_palette.color3 : '' }">
        <img class="timg" :src="slogo" alt="" />
        <span class="name" :style="{ color: configure.color_palette.color2 }">Restaurant Name</span>
        <div class="cart-icon" :style="{ backgroundColor: configure.color_palette.color2 }">
            <span class="iconfont icon-cart" :style="{ color: configure.color_palette.color3 }"></span>
            <span class="cart-num" :style="{ color: configure.color_palette.color3 }">0</span>
        </div>
        <span class="iconfont icon-order" :style="{ color: configure.color_palette.color2 }"></span>
        <div class="lang" :style="{ backgroundColor: configure.color_palette.color2 }">
            <span class="iconfont icon-lang" :style="{ color: configure.color_palette.color3 }"></span>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'NavPreview',
    components: {},
    props: {},
    data() {
        return {
            bgClass: {},
            slogo: '',
        };
    },
    watch: {
        configure: {
            handler: function (newValue) {
                this.bgClass = {
                    background: newValue.background_cover.indexOf('#') == 0 ? newValue.background_cover : `url(${newValue.background_cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
            },
            immediate: true,
        },
    },
    async created() {
        this.slogo = window.localStorage.getItem('slogo');
    },
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
    },
});
</script>
<style scoped lang="less">
.navigation {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 26px;
    width: 100%;
    height: 63px;
    color: #fff;
    font-size: 16px;
    .name {
        font-weight: 600;
    }
    .timg {
        margin-right: 25px;
        width: 31px;
        height: 31px;
    }
    .cart-icon {
        display: flex;
        align-items: center;
        margin-left: auto;
        width: 68px;
        height: 25px;
        border-radius: 30px;
        .iconfont {
            margin-left: 10px;
        }
        .cart-num {
            margin-left: 16px;
            font-size: 12px;
        }
    }
    .icon-order {
        margin-left: 30px;
        font-size: 26px;
    }
    .lang {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }
}
</style>
