<template>
    <a-modal class="milePop" title="Choose a card layout:" :width="800" v-model="visible" ok-text="Confirm" @ok="confirm">
        <div class="layout-box">
            <div :class="['item', active == '2' ? 'active' : '']" @click="active = '2'">
                <div class="card vertical">
                    <div class="detail">
                        <div class="img-box">image</div>
                        <div class="text-box"></div>
                    </div>
                </div>
            </div>
            <div class="item" :class="['item', active == 1 ? 'active' : '']" @click="active = '1'">
                <div class="card horizontal">
                    <div class="detail">
                        <div class="img-box">image</div>
                        <div class="text-box"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="item" :class="['item', active == 'right' ? 'active' : '']" @click="active = 'right'">
                <div class="card horizontal">
                    <div class="detail">
                        <div class="text-box"></div>
                        <div class="img-box">image</div>
                    </div>
                </div>
            </div> -->
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';

export default Vue.extend({
    name: 'LayoutChoose',
    props: {},
    data() {
        return {
            visible: false,
            active: 1,
        };
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
        show() {
            this.visible = true;
        },
        confirm() {
            this.visible = false;
            this.$emit('confirm', this.active);
        },
    },
});
</script>
<style scoped lang="less">
.layout-box {
    display: flex;
    flex-wrap: wrap;
    .item {
        margin: 15px;
        text-align: center;
        cursor: pointer;
    }
    .selected {
        display: none;
        margin-top: 16px;
        color: @main-color;
        font-weight: bold;
        font-size: 22px;
    }
    .card {
        padding: 20px;
        border: 2px solid #fff;
        border-radius: 8px;
        cursor: pointer;
        .detail {
            width: 100%;
            height: 100%;
            background-color: #d9d9d9;
            .img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 186px;
                height: 136px;
                background-color: #eeeeee;
            }
            .text-box {
                width: 155px;
                height: 44px;
                background-color: #505050;
            }
        }
        &.vertical {
            width: 260px;
            height: 270px;
            .detail {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-around;
            }
        }
        &.horizontal {
            width: 431px;
            height: 199px;
            .detail {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }

    .active .card {
        border-color: @main-color;
    }
}
</style>
