<template>
    <div class="editor" v-if="JSON.stringify(menu_dict) != '{}'" :style="bgClass">
        <div class="nav" :style="{ width: configure.direction == 'vertical' ? '860px' : '1050px' }" v-if="editable == 1">
            <div class="name">{{ pageSetting.mid ? menu_dict[pageSetting.mid].name : 'category' }}</div>
        </div>
        <nav-preview v-if="editable == 2 || editable == 3"></nav-preview>
        <cate-preview v-if="editable == 2 || editable == 3"></cate-preview>
        <div
            class="operate-area"
            :key="key"
            :style="{
                width: configure.direction == 'vertical' ? '860px' : '1050px',
                height: configure.direction == 'vertical' ? '780px' : '600px',
            }"
        >
            <vdr
                :ref="'card' + item.type"
                :w="CARD_SIZE[item.type].width"
                :h="CARD_SIZE[item.type].height"
                :x="item.x"
                :y="item.y"
                :resizable="false"
                :parent="true"
                :debug="false"
                :isConflictCheck="true"
                :snap="true"
                :snapTolerance="1"
                @refLineParams="getRefLineParams"
                @dragstop="(x, y) => onDragstop(x, y, index)"
                v-for="(item, index) in pageSetting.page.cards"
                :key="pageSetting.mid + index + item.x + item.y"
                drag-cancel=".fullimg"
                v-if="item.type != 'card61' && item.type != 'card51'"
                :draggable="editable == 1 && !item.disabled"
            >
                <card @clickimg="onClickImg(item.fid, index, item.disabled)" :card="item"></card>
            </vdr>
            <vdr
                :ref="'card' + item.type"
                :w="CARD_SIZE[item.type].width"
                :h="CARD_SIZE[item.type].height"
                :x="item.x"
                :y="item.y"
                :resizable="false"
                :parent="true"
                :debug="false"
                :isConflictCheck="true"
                :snap="true"
                :snapTolerance="1"
                @refLineParams="getRefLineParams"
                @dragstop="(x, y) => onDragstop(x, y, index)"
                v-for="(item, index) in pageSetting.page.cards"
                :key="pageSetting.mid + index + item.x + item.y"
                drag-cancel=".name"
                v-if="item.type == 'card51'"
                :draggable="editable == 1 && !item.disabled"
            >
                <card @clickimg="onClickImg(item.fid, index, item.disabled)" :card="item"></card>
            </vdr>
            <vdr
                :ref="'card' + item.type"
                :w="CARD_SIZE[item.type].width"
                :h="item.height"
                :x="item.x"
                :y="item.y"
                :resizable="false"
                :parent="true"
                :debug="false"
                :isConflictCheck="true"
                :snap="true"
                :snapTolerance="1"
                @refLineParams="getRefLineParams"
                @dragstop="(x, y) => onDragstop(x, y, index)"
                v-for="(item, index) in pageSetting.page.cards"
                :key="pageSetting.mid + index + item.x + item.y"
                drag-cancel=".paragraph"
                v-if="item.type == 'card61'"
                :draggable="editable == 1 && !item.disabled"
            >
                <card @clickparagraph="onClickParagraph(item, index)" :card="item"></card>
            </vdr>
            <!--辅助线-->
            <span class="ref-line v-line" v-for="item in vLine" :key="item.id" v-show="item.display" :style="{ left: item.position, top: item.origin, height: item.lineLength }" />
            <span class="ref-line h-line" v-for="item in hLine" :key="item.id" v-show="item.display" :style="{ top: item.position, left: item.origin, width: item.lineLength }" />
        </div>
        <!-- pop -->
        <food-picker ref="foodPicker" :active="pageSetting.mid" @confirm="foodConfirm" @deletecard="onDeleteCard"></food-picker>
        <card-edit ref="cardEdit" :mid="pageSetting.mid" :fid="activeFid" :index="activeIndex" @confirm="cardConfirm" @deletecard="onDeleteCard"></card-edit>
        <paragraph-card-edit ref="paragraphEdit" :card="activeCard" @confirm="paragraphConfirm" @deletecard="onDeleteCard"></paragraph-card-edit>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import Card from '@components/card/Card';
import FoodPicker from '@components/pop/FoodPicker';
import CardEdit from '@components/pop/CardEdit';
import ParagraphCardEdit from '@components/pop/ParagraphCardEdit';
import vdr from 'vue-draggable-resizable-gorkys';
import 'vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css';
import { CARD_SIZE } from '@/utils/constant';
import NavPreview from '@components/common/NavPreview';
import CatePreview from '@components/common/CatePreview';

export default Vue.extend({
    name: 'Preview',
    components: {
        vdr,
        Card,
        FoodPicker,
        CardEdit,
        ParagraphCardEdit,
        NavPreview,
        CatePreview,
    },
    props: {},
    data() {
        return {
            key: 1,
            CARD_SIZE,
            x: 10,
            y: 200,
            vLine: [],
            hLine: [],
            bgClass: {},
            bgValue: '',

            // 选中的卡片信息
            activeIndex: 0,
            activeFid: '',
            activeCard: {},
            history: [],
        };
    },
    watch: {
        configure: {
            handler: function (newValue) {
                if (this._.isEmpty(newValue)) {
                    const token = window.localStorage.getItem('token') || '';
                    this.$router.push({ path: '/admin/index', query: { s: token } });
                }
                if (newValue.image_all_enable) {
                    this.bgValue = newValue.image_all;
                } else {
                    this.bgValue = this.pageSetting.page.background;
                }
            },
            immediate: true,
            deep: true,
        },
        'configure.direction': {
            handler: function () {
                this.key++;
            },
        },
        bgValue: {
            handler(newValue, oldValue) {
                if (!newValue || newValue.indexOf('#') == 0) {
                    this.bgClass = {
                        backgroundColor: newValue || this.configure.color_palette.color3,
                    };
                } else {
                    this.bgClass = {
                        background: `url(${newValue})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    };
                }
            },
            immediate: true,
        },
        'pageSetting.page.background': {
            handler(newValue, oldValue) {
                if (this.configure.image_all_enable) {
                    return;
                }
                this.bgValue = newValue;
            },
            immediate: true,
        },
        operationHistory: {
            handler(newValue, oldValue) {
                this.history = newValue;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {
        let that = this;
        document.onkeydown = function (e) {
            if (e.key == 'z' && e.ctrlKey) {
                if (that.history.length == 0) {
                    that.$notification['warning']({
                        message: 'No history',
                    });
                    return;
                }
                let configure = that.history[that.history.length - 1];
                that.pop_operation_history();
                that.set_configure(configure);
            }
        };
    },
    computed: {
        ...mapGetters({
            pageSetting: 'app/get_page_setting',
            operationHistory: 'app/get_operation_history',
        }),
        ...mapState({
            menu_dict: (state) => state.food.menu_dict,
            configure: (state) => state.app.configure,
            foods_dict: (state) => state.food.foods_dict,
            page: (state) => state.app.page,
            editable: (state) => state.app.editable,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
            add_operation_history: 'app/ADD_OPERATION_HISTORY',
            pop_operation_history: 'app/POP_OPERATION_HISTORY',
        }),

        // 辅助线回调事件
        getRefLineParams(params) {
            const { vLine, hLine } = params;
            let id = 0;
            this.vLine = vLine.map((item) => {
                item['id'] = ++id;
                return item;
            });
            this.hLine = hLine.map((item) => {
                item['id'] = ++id;
                return item;
            });
        },

        onDragstop(x, y, index) {
            let configure = this._.cloneDeep(this.configure);
            this.add_operation_history(configure);

            let count = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    if (count == this.page - 1) {
                        page.cards[index].x = x;
                        page.cards[index].y = y;
                    }
                    count++;
                }
            }
            this.set_configure(configure);
        },

        foodConfirm(e) {
            let index = this.activeIndex;
            let configure = this._.cloneDeep(this.configure);
            let count = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    if (count == this.page - 1) {
                        page.cards[index].fid = e;
                    }
                    count++;
                }
            }
            this.set_configure(configure);
        },
        onClickImg(fid, index, disabled) {
            if (this.editable == 2 || this.editable == 3 || disabled) {
                return;
            }
            if (!fid) {
                this.$refs.foodPicker.show();
            } else {
                this.$refs.cardEdit.show();
            }

            this.activeFid = fid;
            this.activeCard = {};
            this.activeIndex = index;
        },
        cardConfirm(e) {
            let index = this.activeIndex;
            let configure = this._.cloneDeep(this.configure);
            let count = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];

                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    if (count == this.page - 1) {
                        page.cards[index].border = e.borderChecked ? 1 : 0;
                        if (e.coverImg) {
                            page.cards[index].img_url = e.coverImg;
                        }
                        if (e.coverFid) {
                            let oldFid = page.cards[index].fid;
                            // 若是目标fid已有使用,则交换

                            for (let m = 0; m < cate.page_list.length; m++) {
                                let catePage = cate.page_list[m];
                                for (let k = 0; k < catePage.cards.length; k++) {
                                    const card = catePage.cards[k];
                                    if (card.fid == e.coverFid) {
                                        card.fid = oldFid;
                                        break;
                                    }
                                }
                            }

                            page.cards[index].fid = e.coverFid;
                        }
                    }
                    count++;
                }
            }
            this.set_configure(configure);
        },
        onClickParagraph(card, index) {
            if (this.editable == 2 || this.editable == 3) {
                return;
            }
            this.$refs.paragraphEdit.show();

            this.activeFid = '';
            this.activeCard = card;
            this.activeIndex = index;
        },
        paragraphConfirm(e) {
            let index = this.activeIndex;
            let configure = this._.cloneDeep(this.configure);
            let count = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    if (count == this.page - 1) {
                        page.cards[index].text = e.text;
                        page.cards[index].text_color = e.text_color;
                        page.cards[index].height = e.height;
                    }
                    count++;
                }
            }
            this.set_configure(configure);
        },
        onDeleteCard() {
            this.activeFid = '';
            let index = this.activeIndex;
            let configure = this._.cloneDeep(this.configure);
            let count = 0;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    if (count == this.page - 1) {
                        page.cards.splice(index, 1);
                    }
                    count++;
                }
            }
            this.set_configure(configure);
        },
    },
});
</script>
<style scoped lang="less">
.nav {
    height: 41px;
    background-color: #d8d8d8;
    line-height: 41px;
    .name {
        width: 190px;
        height: 100%;
        background-color: @main-color;
        color: #fff;
        text-align: center;
        font-size: 18px;
    }
}
.operate-area {
    position: relative;
}
</style>
