<template>
    <a-modal class="milePop" :title="menu_dict[active].name" :width="800" v-model="visible" :footer="null" @cancel="onCloseModal" v-if="active">
        <div class="flex-box">
            <div class="left">
                <div class="count">{{ menu_dict[active].list.length }} items</div>
                <div class="dividing" style="margin: 20px 0 15px"></div>
                <div class="list">
                    <div class="item" v-for="item in menu_dict[active].list" :key="item.fid">
                        <div class="name">{{ item.fname }}</div>
                        <div class="added" v-show="addedFoodList(active).indexOf(item.fid) > -1">added</div>
                        <div class="remove" v-show="pickFoodList.indexOf(item.fid) > -1" @click="remove(item)">remove</div>
                        <a-icon
                            :class="['add-icon', activeLayout ? 'active' : '']"
                            type="plus-circle"
                            theme="filled"
                            v-show="addedFoodList(active).indexOf(item.fid) == -1 && pickFoodList.indexOf(item.fid) == -1"
                            @click="add(item)"
                        />
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="info">added pages:{{ categorySetting(active) ? categorySetting(active).page_list.length : 0 }}</div>
                <a-button type="primary" style="margin-top: 18px" @click="mangePages" v-show="categorySetting(active) && categorySetting(active).page_list.length > 0"> Manage Pages </a-button>
                <div class="info" style="margin-top: 38px">Choose a Layout</div>
                <template v-if="!activeLayout">
                    <a-button style="margin-top: 18px" @click="designLayout">Design my own layout</a-button>
                    <div class="num" style="margin: 30px 0 15px">
                        items per page:
                        <a-select v-model="num" style="width: 100px; margin-left: 10px">
                            <a-select-option :value="1"> 1 </a-select-option>
                            <a-select-option :value="2"> 2 </a-select-option>
                            <a-select-option :value="3"> 3 </a-select-option>
                            <a-select-option :value="4"> 4 </a-select-option>
                            <a-select-option :value="5"> 5 </a-select-option>
                            <a-select-option :value="6"> 6 </a-select-option>
                            <a-select-option :value="8"> 8 </a-select-option>
                        </a-select>
                    </div>

                    <a-carousel arrows>
                        <div slot="prevArrow" class="custom-slick-arrow" style="left: 0px; z-index: 1">
                            <a-icon type="left-circle" theme="filled" />
                        </div>
                        <div slot="nextArrow" class="custom-slick-arrow" style="right: 0px">
                            <a-icon type="right-circle" theme="filled" />
                        </div>
                        <layout-thumbnail class="thumbnail" v-for="item in showLayoutList" :type="item" :key="item" :button-visible="true" @confirm="layoutConfirm"></layout-thumbnail>
                    </a-carousel>
                </template>
                <template v-if="activeLayout">
                    <layout-thumbnail class="thumbnail" :type="activeLayout" :foods="pickFoodList" style="margin-top: 10px"></layout-thumbnail>
                    <div>
                        <span>Layout Selected</span>
                        <a-button type="link" size="large" @click="changeLayout"> Change </a-button>
                    </div>
                    <a-button type="primary" size="large" @click="savePage" style="margin-top: 10px" v-show="pickFoodList.length == this.num">Save this page</a-button>
                </template>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import LayoutThumbnail from '@components/layout/LayoutThumbnail';
import { LAYOUT_TYPE_LIST } from '@/utils/constant';
import { THEME_LIST } from '@/utils/theme';
import { LAYOUT_LIST } from '@/utils/layout';

export default Vue.extend({
    name: 'CategorySetting',
    components: {
        LayoutThumbnail,
    },

    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            LAYOUT_TYPE_LIST,
            LAYOUT_LIST,
            THEME_LIST,
            visible: false,
            num: 1,
            pickFoodList: [],
            activeLayout: '',
        };
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            addedFoodList: 'app/get_added_food_list',
            pageInsert: 'app/get_page_insert',
            categorySetting: 'app/get_category_setting',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            menu_dict: (state) => state.food.menu_dict,
        }),
        showLayoutList() {
            return this.LAYOUT_TYPE_LIST[this.configure.direction].filter((e) => {
                if (e > this.num * 10 && e < (this.num + 1) * 10) {
                    return e;
                }
            });
        },
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
            set_nav_index: 'app/SET_NAV_INDEX',
        }),

        show() {
            this.visible = true;
        },
        mangePages() {
            this.visible = false;
            this.$emit('mange', this.active);
        },
        add(food) {
            if (!this.activeLayout) {
                return;
            }
            if (this.pickFoodList.length >= this.num * 1) {
                this.$notification['warning']({
                    message: 'The number of items exceeds the limit',
                });
                return;
            }
            this.pickFoodList.push(food.fid);
        },
        remove(food) {
            for (let i = 0; i < this.pickFoodList.length; i++) {
                if (food.fid == this.pickFoodList[i]) {
                    this.pickFoodList.splice(i, 1);
                    break;
                }
            }
        },
        layoutConfirm(e) {
            this.activeLayout = e;
        },
        changeLayout() {
            this.pickFoodList = [];
            this.activeLayout = '';
        },
        onCloseModal() {
            this.pickFoodList = [];
            this.activeLayout = '';
        },
        savePage() {
            let configure = this._.cloneDeep(this.configure);
            let exit = 0;
            let activeCate;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                if (cate.mid == this.active) {
                    activeCate = cate;
                    exit = 1;
                    break;
                }
            }
            let layoutSetting = this._.cloneDeep(this.LAYOUT_LIST[this.activeLayout]);
            layoutSetting.cards = layoutSetting.cards.map((e) => {
                e.border = this.configure.frame_display ? 1 : 0;
                return e;
            });
            for (let j = 0; j < layoutSetting.cards.length; j++) {
                const item = layoutSetting.cards[j];
                item.fid = this.pickFoodList[j];
            }
            let page = {
                background: this.THEME_LIST[configure.theme_name].background_cover[configure.direction] || configure.color_palette.color3,
                cards: layoutSetting.cards,
            };
            if (exit) {
                activeCate.page_list.push(page);
            } else {
                activeCate = {
                    mid: this.active,
                    page_list: [],
                };
                activeCate.page_list.push(page);
                configure.category_list.push(activeCate);
            }

            this.set_configure(configure);
            this.$store.commit('app/SET_PAGE', this.pageInsert(this.active));
            this.onCloseModal();
        },
        designLayout() {
            let configure = this._.cloneDeep(this.configure);
            let exit = 0;
            let activeCate;
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                if (cate.mid == this.active) {
                    activeCate = cate;
                    exit = 1;
                    break;
                }
            }

            let page = {
                background: this.THEME_LIST[configure.theme_name].background_cover[configure.direction] || configure.color_palette.color3,
                cards: [],
            };
            if (exit) {
                activeCate.page_list.push(page);
            } else {
                activeCate = {
                    mid: this.active,
                    page_list: [],
                };
                activeCate.page_list.push(page);
                configure.category_list.push(activeCate);
            }

            this.set_configure(configure);
            this.$store.commit('app/SET_PAGE', this.pageInsert(this.active));
            this.set_nav_index(3);
            this.onCloseModal();
            this.visible = false;
            this.$notification['success']({
                message: 'Success',
                description: 'Blank Page Created!',
            });
        },
    },
});
</script>
<style scoped lang="less">
.flex-box {
    display: flex;
    .left {
        flex-basis: 50%;
        flex-shrink: 0;
        border-right: 1px solid #c9c9c9;
        .list {
            overflow-y: auto;
            padding-right: 10px;
            height: 600px;
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                font-size: 16px;
                .name {
                    max-width: 200px;
                }
                .add-icon {
                    color: @info-color;
                    font-size: 20px;
                    cursor: pointer;
                    &.active {
                        color: @main-color;
                    }
                }
                .remove {
                    color: @info-color;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .added {
                    color: @info-color;
                }
            }
        }
    }
    .right {
        padding-left: 50px;
        .info {
            font-size: 16px;
        }
        .thumbnail {
            font-size: 14px;
        }
    }
}

.ant-carousel {
    width: 336px;
    height: 350px;
}
.ant-carousel /deep/ .slick-dots-bottom {
    bottom: 60px;
}
.ant-carousel /deep/ button {
    background-color: @main-color !important;
}
.ant-carousel /deep/ .slick-active button {
    background-color: @main-color !important;
}
.ant-carousel /deep/ .slick-slide {
    display: flex !important;
    justify-content: center;
}
.ant-carousel /deep/ .custom-slick-arrow {
    width: 25px;
    height: 25px;
    color: @main-color;
    font-size: 28px;
}

.ant-carousel /deep/ .custom-slick-arrow:hover {
    color: @main-color;
}
.ant-carousel /deep/ .custom-slick-arrow:before {
    display: none;
}
</style>
