<template>
    <div class="container">
        <a-menu style="width: 160px; flex-shrink: 0" v-model="activeMenu" mode="inline" v-if="editable == 1">
            <a-menu-item :key="1">
                <span>Styles</span>
            </a-menu-item>
            <a-menu-item :key="2">
                <span>Add Products</span>
            </a-menu-item>
            <a-menu-item :key="3" v-if="configure.category_layout != 'left'">
                <span>Page Settings</span>
            </a-menu-item>
            <a-menu-item :key="4">
                <span>More Features</span>
            </a-menu-item>
        </a-menu>
        <etitor-styles class="middle" v-show="activeMenu == 1 && editable == 1"></etitor-styles>
        <etitor-products class="middle" v-show="activeMenu == 2 && editable == 1"></etitor-products>
        <etitor-page class="middle" v-show="activeMenu == 3 && editable == 1"></etitor-page>
        <etitor-features class="middle" v-show="activeMenu == 4 && editable == 1"></etitor-features>
        <div class="right">
            <div class="operation">
                <a-button type="primary" @click="handleBorderImg(false)" v-if="editable == 1">Save</a-button>
                <a-button @click="preview" v-if="editable == 1">Preview</a-button>
                <a-button @click="exitConfirm" v-if="editable == 1">Exit Editor</a-button>
                <a-button @click="exit" v-if="editable == 2 || editable == 3">Exit Editor</a-button>
                <a-button type="primary" @click="backEditor" v-if="editable == 3">Back Editor</a-button>
            </div>
            <div style="display: flex" v-show="activeMenu != 4" v-if="configure.category_layout != 'left'">
                <pagination></pagination>
                <preview style="margin-top: 46px"></preview>
            </div>
            <div style="display: flex" v-show="activeMenu != 4" v-if="configure.category_layout == 'left'">
                <pagination-left></pagination-left>
                <preview-left style="margin-top: 46px"></preview-left>
            </div>
            <div style="display: flex; padding-top: 23px" v-show="activeMenu == 4">
                <features-edit></features-edit>
                <features-preview></features-preview>
            </div>
        </div>
        <!-- pop -->
        <upload-border-img ref="uploadBorderImg" @confirm="imgConfirm"></upload-border-img>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import EtitorStyles from '@components/common/EtitorStyles';
import EtitorProducts from '@components/common/EtitorProducts';
import EtitorPage from '@components/common/EtitorPage';
import EtitorFeatures from '@components/common/EtitorFeatures';
import Preview from '@components/common/Preview';
import Pagination from '@components/common/Pagination';
import PreviewLeft from '@components/common/PreviewLeft';
import PaginationLeft from '@components/common/PaginationLeft';
import FeaturesPreview from '@components/common/FeaturesPreview';
import FeaturesEdit from '@components/common/FeaturesEdit';
import UploadBorderImg from '@components/pop/UploadBorderImg';
import axiosApi from '@kits/axiosApi';

export default {
    name: 'Editor',
    components: {
        EtitorStyles,
        EtitorProducts,
        EtitorPage,
        EtitorFeatures,
        Preview,
        Pagination,
        PreviewLeft,
        PaginationLeft,
        FeaturesPreview,
        FeaturesEdit,
        UploadBorderImg,
    },
    data() {
        return {
            aj_host: this.$store.state.aj_host,
            activeMenu: [1],
            borderImgList: {},
            closeAfterSave: false,
        };
    },
    beforeMount() {},
    updated() {},
    beforeCreate() {},
    created() {},
    watch: {
        navIndex(newValue) {
            this.activeMenu = [newValue];
        },
    },
    computed: {
        ...mapGetters({
            sid: 'app/get_sid',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            navIndex: (state) => state.app.nav_index,
            editable: (state) => state.app.editable,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING',
            set_editable: 'app/SET_EDITABLE',
            set_page: 'app/SET_PAGE',
            set_configure: 'app/SET_CONFIGURE',
        }),
        handleBorderImg(close = false) {
            this.closeAfterSave = close;
            // 验证卡片是否都设置商品
            let configure = this._.cloneDeep(this.configure);
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    for (let k = 0; k < page.cards.length; k++) {
                        const card = page.cards[k];
                        if (!card.fid && card.type != 'card61') {
                            this.$notification['error']({
                                message: 'Error',
                                description: 'Please set the card food',
                            });
                            return;
                        }
                    }
                }
            }

            this.setLoading({
                show_loading: true,
            });
            this.$refs.uploadBorderImg.show();
        },
        imgConfirm(e) {
            this.borderImgList = e;
            this.save();
        },
        save() {
            let configure = this._.cloneDeep(this.configure);

            // 转换坐标为百分比
            let width = configure.direction == 'vertical' ? '860' : '1050';
            let height = configure.direction == 'vertical' ? '780' : '600';
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    for (let k = 0; k < page.cards.length; k++) {
                        const card = page.cards[k];
                        card.x = Number((card.x / width).toFixed(2));
                        card.y = Number((card.y / height).toFixed(2));
                    }
                }
            }
            configure.border_img = this.borderImgList.imageBorder;
            configure.quantity_border_img = this.borderImgList.priceBorder;
            configure.quantity_bg_img = this.borderImgList.priceBackground;
            configure.category_img = this.borderImgList.categoryIcon;

            if (configure.smid) {
                axiosApi
                    .post(this.aj_host + '/weapp/admin/v2/supermenu/update', {
                        smid: configure.smid,
                        configure: configure,
                    })
                    .then((res) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        if (res.data.data.code == 100000) {
                            this.$notification['success']({
                                message: 'Success',
                                description: 'Successfully!',
                            });
                            if (this.closeAfterSave) {
                                this.exit();
                            }
                        } else {
                            this.$notification['error']({
                                message: 'Error',
                                description: res.data.data.msg,
                            });
                        }
                    })
                    .catch((err) => {
                        this.setLoading({
                            show_loading: false,
                        });
                    });
            } else {
                axiosApi
                    .post(this.aj_host + '/weapp/admin/v2/supermenu/create', {
                        sid: this.sid,
                        configure: configure,
                    })
                    .then((res) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        if (res.data.data.code == 100000) {
                            let configure = this._.cloneDeep(this.configure);
                            configure.smid = res.data.data.data;
                            this.set_configure(configure);

                            this.$notification['success']({
                                message: 'Success',
                                description: 'Successfully!',
                            });
                            if (this.closeAfterSave) {
                                this.exit();
                            }
                        } else {
                            this.$notification['error']({
                                message: 'Error',
                                description: res.data.data.msg,
                            });
                        }
                    })
                    .catch((err) => {
                        this.setLoading({
                            show_loading: false,
                        });
                    });
            }
        },
        exitConfirm() {
            this.$confirm({
                title: 'Save changes',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    this.handleBorderImg(true);
                },
                onCancel: () => {
                    this.exit();
                },
            });
        },
        exit() {
            const token = window.localStorage.getItem('token') || '';
            this.set_page(1);
            this.$router.push({ path: '/admin/index', query: { s: token } });
        },
        preview() {
            if (this.activeMenu[0] == 4) {
                this.activeMenu = [1];
            }
            this.set_editable(3);
        },
        backEditor() {
            this.set_editable(1);
        },
    },
};
</script>

<style scoped lang="less">
.container {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100vh;
}

.middle {
    flex-shrink: 0;
}
.right {
    flex: 1;
    background-color: #000000;
    .operation {
        display: flex;
        align-items: center;
        padding-left: 20px;
        width: 100%;
        height: 63px;
        background-color: #fff;
        & /deep/ .ant-btn {
            margin-right: 20px;
            width: 150px;
        }
    }
}
</style>
