<template>
    <div class="card-wrap">
        <!-- 11 -->
        <div class="card small horizontal" v-if="card.type == 'card11'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-img${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 12 -->
        <div class="card small vertical" v-if="card.type == 'card12'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-img${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 21 -->
        <div class="card medium horizontal" v-if="card.type == 'card21'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-imgm${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 22 -->
        <div class="card medium vertical" v-if="card.type == 'card22'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-imgm${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 31 -->
        <div class="card large horizontal" v-if="card.type == 'card31'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-imgl${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 32 -->
        <div class="card large vertical" v-if="card.type == 'card32'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-imgl${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 41 -->
        <div class="card extra-large horizontal" v-if="card.type == 'card41'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-imgxl${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 42 -->
        <div class="card extra-large vertical" v-if="card.type == 'card42'">
            <div class="img">
                <span :class="['border', 'iconfont', `icon-imgxl${configure.theme_name}`]" v-if="card.border == 1" :style="{ color: configure.color_palette.color1 }"></span>
                <div class="img-wrap">
                    <img class="fullimg" @click="onClickImg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic : '') || placeholder" alt="" />
                </div>
            </div>
            <div class="info">
                <div class="name" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 51 -->
        <div class="card no-image" v-if="card.type == 'card51'">
            <div class="info">
                <div class="name" @click="onClickImg" :style="{ color: configure.item_name_color, fontFamily: configure.font_family.item_name }">
                    {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                </div>
                <div class="price">
                    <span :class="['price-border', 'iconfont', `icon-priceb${configure.theme_name}`]" :style="{ color: configure.color_palette.color1 }"></span>
                    <span :class="['price-bg', 'iconfont', `icon-price${configure.theme_name}`]" :style="{ color: configure.color_palette.color3 }"></span>
                    <div class="price-info" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.price }">
                        $1.99 <a-icon class="icon" :style="{ color: configure.color_palette.color1 }" type="plus-circle" theme="filled" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 61 -->
        <div class="card text" v-if="card.type == 'card61'">
            <div class="paragraph" @click="onClickParagraph" :style="{ color: card.text_color, fontFamily: configure.font_family.paragraph }">
                {{ card.text }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';

export default Vue.extend({
    name: 'Crad',
    components: {},
    props: {
        // type: {
        //     type: String,
        //     default: '',
        // },
        // color: {
        //     type: String,
        //     default: '#34C759',
        // },
        // img: {
        //     type: String,
        //     default: '',
        // },
        // name: {
        //     type: String,
        //     default: '',
        // },
        // border: {
        //     type: Number,
        //     default: 0,
        // },
        card: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            placeholder: require('@assets/images/placeholder.png'),
        };
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
            foods_dict: (state) => state.food.foods_dict,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        onClickImg() {
            this.$emit('clickimg');
        },
        onClickParagraph() {
            this.$emit('clickparagraph');
        },
    },
});
</script>
<style scoped lang="less">
.card-wrap {
    .card {
        overflow: hidden;
        box-sizing: border-box;
        .img {
            position: relative;
            flex-shrink: 0;
            .fullimg {
                height: auto;
            }
            .img-wrap {
                position: relative;
                z-index: 2;
                overflow: hidden;
                margin: 40px 0 0 40px;
                cursor: pointer;
            }
            .border {
                position: absolute;
                z-index: 1;
            }
        }
        .info {
            margin-left: 40px;
            .price {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                box-sizing: border-box;
                margin-top: 30px;
                width: 130px;
                height: 40px;
                font-size: 18px;
                .price-bg {
                    position: absolute;
                    font-size: 40px;
                }
                .price-border {
                    position: absolute;
                    left: -1px;
                    z-index: 1;
                    font-size: 41px;
                }
                .price-info {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 22px;
                    width: 110px;
                }
            }
        }
        .name {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            word-break: break-all;
            line-height: 25px;

            -webkit-line-clamp: 2;
        }
        &.small {
            .name {
                font-size: 20px;
            }
            .img {
                .border {
                    top: 0px;
                    left: 30px;
                    font-size: 120px;
                }
                .fullimg {
                    width: 100%;
                }
                .img-wrap {
                    width: 100px;
                    height: 100px;
                }
            }
            &.horizontal {
                display: flex;
                width: 330px;
                height: 180px;
                .info {
                    margin-top: 30px;
                }
            }
            &.vertical {
                display: flex;
                flex-direction: column;
                width: 180px;
                height: 300px;
                .info {
                    margin-top: 40px;
                    margin-left: 15px;
                }
            }
        }
        &.medium {
            .name {
                font-size: 22px;
            }
            .img {
                .border {
                    top: -27px;
                    left: 25px;
                    font-size: 200px;
                }
                .fullimg {
                    width: 100%;
                }
                .img-wrap {
                    width: 220px;
                    height: 165px;
                }
            }
            &.horizontal {
                display: flex;
                width: 500px;
                height: 245px;
                .info {
                    margin-top: 58px;
                }
            }
            &.vertical {
                display: flex;
                flex-direction: column;
                width: 300px;
                height: 375px;
                .info {
                    margin-top: 40px;
                }
            }
        }
        &.large {
            .name {
                font-size: 22px;
            }
            .img {
                .border {
                    top: -78px;
                    left: 21px;
                    font-size: 400px;
                }
                .fullimg {
                    width: 100%;
                }
                .img-wrap {
                    width: 480px;
                    height: 360px;
                }
            }
            &.horizontal {
                display: flex;
                width: 760px;
                height: 440px;
                .info {
                    margin-top: 270px;
                }
            }
            &.vertical {
                display: flex;
                flex-direction: column;
                width: 560px;
                height: 570px;
                .info {
                    margin-top: 40px;
                }
            }
        }
        &.extra-large {
            .name {
                font-size: 24px;
            }
            .img {
                .border {
                    top: -125px;
                    left: 14px;
                    font-size: 554px;
                }
                .fullimg {
                    width: 100%;
                }
                .img-wrap {
                    width: 500px;
                    height: 500px;
                }
            }
            &.horizontal {
                display: flex;
                width: 780px;
                height: 580px;
                .info {
                    margin-top: 270px;
                }
            }
            &.vertical {
                display: flex;
                flex-direction: column;
                width: 580px;
                height: 716px;
                .info {
                    margin-top: 40px;
                    margin-left: 345px;
                }
            }
        }
        &.no-image {
            width: 360px;
            height: 65px;
            .name {
                font-size: 24px;
                cursor: pointer;
            }
            .info {
                display: flex;
                justify-content: space-between;
                margin: 0;
                .name {
                    width: 150px;
                }
                .price {
                    flex: 0;
                    flex-basis: 129px;
                    margin-top: 0;
                    .price-info {
                        width: 110px;
                    }
                }
            }
        }
        &.text {
            padding: 20px;
            width: 340px;
            font-weight: 500;
            font-size: 18px;
            .paragraph {
                word-break: break-all;
                cursor: pointer;
            }
        }
    }
}
</style>
