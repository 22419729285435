<template>
    <div class="layout-thumbnail">
        <!-- rows2 -->
        <div class="frame grid2" v-if="rows == 2 && image">
            <div class="card" v-for="item in 6" :key="item">
                <div class="img">
                    <img class="adjustimg" alt="" src="@/assets/images/food.png" />
                </div>
                <div class="text">text</div>
            </div>
        </div>
        <!-- rows3 -->
        <div class="frame grid3" v-if="rows == 3 && image">
            <div class="card" v-for="item in 9" :key="item">
                <div class="img">
                    <img class="adjustimg" alt="" src="@/assets/images/food.png" />
                </div>
                <div class="text">text</div>
            </div>
        </div>
        <!-- rows4 -->
        <div class="frame grid4" v-if="rows == 4 && image">
            <div class="card" v-for="item in 12" :key="item">
                <div class="img">
                    <img class="adjustimg" alt="" src="@/assets/images/food.png" />
                </div>
                <div class="text">text</div>
            </div>
        </div>
        <!-- rows5 -->
        <div class="frame grid5" v-if="rows == 5 && image">
            <div class="card" v-for="item in 15" :key="item">
                <div class="img">
                    <img class="adjustimg" alt="" src="@/assets/images/food.png" />
                </div>
                <div class="text">text</div>
            </div>
        </div>
        <!-- rows2 no-image -->
        <div class="frame grid2 no-image" v-if="rows == 2 && !image">
            <div class="card" v-for="item in 12" :key="item">
                <div class="text">text</div>
            </div>
        </div>
        <!-- rows3 no-image -->
        <div class="frame grid3 no-image" v-if="rows == 3 && !image">
            <div class="card" v-for="item in 18" :key="item">
                <div class="text">text</div>
            </div>
        </div>
        <!-- rows4 no-image -->
        <div class="frame grid4 no-image" v-if="rows == 4 && !image">
            <div class="card" v-for="item in 24" :key="item">
                <div class="text">text</div>
            </div>
        </div>
        <!-- rows5 no-image -->
        <div class="frame grid5 no-image" v-if="rows == 5 && !image">
            <div class="card" v-for="item in 30" :key="item">
                <div class="text">text</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'ColorPalette',
    props: {
        rows: {
            type: Number,
            default: 2,
        },
        image: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            foods_dict: (state) => state.food.foods_dict,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        confirm() {
            this.$emit('confirm', this.type);
        },
    },
});
</script>
<style scoped lang="less">
.layout-thumbnail {
    position: relative;
    width: 24em !important;
    height: 32em;

    .button-confirm {
        position: absolute;
        bottom: 0;
    }
    .frame {
        display: grid;
        margin: 0 auto;
        border: 1px solid #979797;
        border-right: none;
        border-bottom: none;
        .card {
            height: 120px;
            border-right: 1px solid #979797;
            border-bottom: 1px solid #979797;
            .text {
                margin-top: 1em;
                margin-left: 1em;
                font-weight: bold;
            }
        }
        &.no-image {
            .card {
                height: 60px;
                .text {
                    margin-bottom: 2em;
                }
            }
        }
        &.grid2 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            .card {
                display: flex;
                .img {
                    width: 60%;
                }
            }
        }
        &.grid3 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            .card {
                .img {
                    height: 60%;
                }
            }
        }
        &.grid4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            .card {
                .img {
                    height: 60%;
                }
            }
        }
        &.grid5 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            .card {
                .img {
                    height: 60%;
                }
            }
        }
    }
}
</style>
