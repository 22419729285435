<template>
    <div class="middle">
        <div class="box">
            <div class="title">Current General Layout:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <img class="layout-img" :src="layoutTopImg" alt="" v-show="configure.category_layout == 'top'" />
            <img class="layout-img" :src="layoutBottomImg" alt="" v-show="configure.category_layout == 'bottom'" />
            <img class="layout-img" :src="layoutLeftImg" alt="" v-show="configure.category_layout == 'left'" />
            <a-button type="primary" size="large" style="margin-top: 38px" @click="$refs.layout.show()"> Change Layout </a-button>
        </div>
        <div class="box">
            <div class="title">Theme:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="theme">
                <img class="theme-img" :style="{ borderColor: configure.color_palette.color3 }" :src="THEME_LIST[configure.theme_name].background_cover[configure.direction]" alt="" />
                <div class="theme-name" :style="{ color: themeNameColor[configure.theme_name] }">{{ configure.theme_name }}</div>
                <a-button class="theme-change" type="primary" size="large" @click="openTheme"> Change </a-button>
            </div>
        </div>
        <div class="box" v-if="configure.category_layout != 'left'">
            <div class="title">Menu Background:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div style="display: flex; align-items: center">
                <a-switch :default-checked="configure.image_all_enable" @change="onChangeImageAll" />
                <span style="font-size: 20px; margin-left: 10px">Use single image for all pages</span>
            </div>
            <div class="img-wrap">
                <div class="fullimg" :style="bgClass"></div>
                <a-button class="change" type="primary" @click="changeImg" size="large">Change</a-button>
            </div>
        </div>
        <div class="box">
            <div class="title">Color Palette:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="palette">
                <div class="color" @click="openColor(1)" :style="{ backgroundColor: configure.color_palette.color1 }"><a-icon type="edit" /></div>
                <div class="color" @click="openColor(2)" :style="{ backgroundColor: configure.color_palette.color2 }"><a-icon type="edit" /></div>
                <div class="color" @click="openColor(3)" :style="{ backgroundColor: configure.color_palette.color3 }"><a-icon type="edit" /></div>
            </div>
            <a-button type="primary" size="large" style="margin: 30px auto 0" block @click="savePalette"> save as new template </a-button>
            <div class="title" style="margin-top: 30px">All Palettes:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="subhead">Default:</div>
            <div class="color-list">
                <div class="item" v-for="(item, index) in colorPalette" :key="index">
                    <div class="color" :style="{ background: item.color1 }"></div>
                    <div class="color" :style="{ background: item.color2 }"></div>
                    <div class="color" :style="{ background: item.color3 }"></div>
                    <div class="use" @click="choosePalette(item)">Use</div>
                </div>
            </div>
            <div class="subhead">Custom:</div>
            <div class="color-list">
                <div class="item" v-for="(item, index) in color_palette" :key="index">
                    <div class="color" :style="{ background: item.color1 }"></div>
                    <div class="color" :style="{ background: item.color2 }"></div>
                    <div class="color" :style="{ background: item.color3 }"></div>
                    <div class="use" @click="choosePalette(item)">Use</div>
                </div>
            </div>
        </div>
        <div class="box" v-if="configure.category_layout != 'left'">
            <div class="title">Current Font Family:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="font">
                <div class="item">
                    <div class="name">{{ configure.font_family.store_name }}</div>
                </div>
                <div class="subhead">Custom:</div>
                <div class="dividing" style="margin: 12px 0 24px"></div>
                <div class="item" v-for="item in FONT_LIST" :key="item">
                    <div class="name">{{ item }}</div>
                    <span class="use" v-show="configure.font_family.store_name != item" @click="fontChange(item)">use</span>
                    <span class="inuse" v-show="configure.font_family.store_name == item">in use</span>
                </div>
            </div>
        </div>
        <div class="box" v-if="configure.category_layout != 'left'">
            <div class="title">Item Name Text Color:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <a-radio-group v-model="itemNameRadio" size="large" @change="onChangeItemColor">
                <a-radio class="color-radio" :value="1"> Default </a-radio>
                <a-radio class="color-radio" :value="2">
                    # Custom <span class="custom-color" v-show="itemNameRadio == 2" :style="{ color: configure.item_name_color }" @click="openCustomColor">{{ configure.item_name_color }}</span>
                </a-radio>
            </a-radio-group>
        </div>
        <div class="box" v-if="configure.category_layout != 'left'">
            <div class="title">Toggle image frame display:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div style="display: flex; align-items: center">
                <a-switch :default-checked="configure.frame_display" @change="onChangeDisplay" />
                <span style="font-size: 20px; margin-left: 10px">{{ configure.frame_display ? 'Display' : 'Hide' }}</span>
            </div>
        </div>
        <div class="box">
            <div class="title">Current View Direction:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="direction">
                <div class="vertical" v-if="configure.direction == 'vertical'">menu</div>
                <div class="horizontally" v-else>menu</div>
                <div class="info">
                    <div class="name">{{ configure.direction == 'vertical' ? 'Vertical' : 'Horizontal' }}</div>
                    <div class="desc">*Your menu is set to be viewed {{ configure.direction == 'vertical' ? 'vertically' : 'horizontally' }}.</div>
                </div>
            </div>
            <a-button type="primary" size="large" style="margin-top: 46px" @click="$refs.direction.show()"> Change </a-button>
        </div>
        <!-- pop -->
        <theme-choose ref="theme" :active="configure.theme_name" @confirm="themeConfirm"></theme-choose>
        <layout-choose ref="layout" :active="configure.category_layout" @confirm="layoutConfirm"></layout-choose>
        <direction-choose ref="direction" :active="configure.direction" @confirm="directionConfirm"></direction-choose>
        <color-picker ref="paletteColor" :active="activeColor" @confirm="paletteColorConfirm"></color-picker>
        <color-picker ref="customColor" :active="activeColor" @confirm="customColorConfirm"></color-picker>
        <edit-background ref="background" :active="configure.image_all" @confirm="backgroundConfirm"></edit-background>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import ThemeChoose from '@components/pop/ThemeChoose';
import LayoutChoose from '@components/pop/LayoutChoose';
import DirectionChoose from '@components/pop/DirectionChoose';
import ColorPicker from '@components/pop/ColorPicker';
import { FONT_LIST } from '@/utils/constant';
import { THEME_LIST } from '@/utils/theme';
import EditBackground from '@components/pop/EditBackground';

export default Vue.extend({
    name: 'EtitorStyles',
    components: {
        ThemeChoose,
        LayoutChoose,
        DirectionChoose,
        ColorPicker,
        EditBackground,
    },
    props: {},
    data() {
        return {
            aj_host: this.$store.state.aj_host,
            FONT_LIST,
            THEME_LIST,
            itemNameRadio: 1,
            activeColor: '',
            editColorIndex: '',
            layoutTopImg: require('@assets/images/layout_thumb_top.png'),
            layoutBottomImg: require('@assets/images/layout_thumb_bottom.png'),
            layoutLeftImg: require('@assets/images/layout3.png'),
            themeNameColor: {
                basic: '#000000',
                classic: '#C6A447',
                modern: '#ffffff',
            },
            bgClass: {},
        };
    },
    watch: {
        'configure.image_all': {
            handler(newValue, oldValue) {
                if (!newValue || newValue.indexOf('#') == 0) {
                    this.bgClass = {
                        backgroundColor: newValue || this.configure.color_palette.color3,
                    };
                } else {
                    this.bgClass = {
                        background: `url(${newValue})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    };
                }
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            colorPalette: 'app/get_color_palette', //从主题来的配置颜色
            sid: 'app/get_sid',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            color_palette: (state) => state.app.color_palette, //自定义保存在数据库的配置色
        }),
    },
    methods: {
        ...mapActions({
            resetFrameDisplay: 'app/resetFrameDisplay',
            emptyCategory: 'app/emptyCategory',
            switchLayoutLeft: 'app/switchLayoutLeft',
        }),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
            setLoading: 'app/APP_SET_LOADING',
            set_color_palette: 'app/SET_COLOR_PALETTE',
            set_page: 'app/SET_PAGE',
            set_page_left: 'app/SET_PAGE_LEFT',
        }),
        openTheme() {
            this.$refs.theme.show();
        },
        themeConfirm(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.theme_name = this.THEME_LIST[e].theme_name;
            configure.border_img = this.THEME_LIST[e].border_img;
            configure.category_img = this.THEME_LIST[e].category_img;
            configure.color_palette = this._.cloneDeep(this.THEME_LIST[e].color_palette);
            configure.font_family = this._.cloneDeep(this.THEME_LIST[e].font_family);
            configure.item_name_color = this.THEME_LIST[e].color_palette.color2;
            configure.background_cover = this.THEME_LIST[e].background_cover[configure.direction];
            this.set_configure(configure);
        },
        onChangeImageAll(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.image_all_enable = e;
            this.set_configure(configure);
        },
        changeImg() {
            this.$refs.background.show();
        },
        backgroundConfirm(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.image_all = e;
            this.set_configure(configure);
        },
        onChangeItemColor(e) {
            if (e.target.value == 1) {
                let configure = this._.cloneDeep(this.configure);
                configure.item_name_color = this.THEME_LIST[configure.theme_name].item_name_color;
                this.set_configure(configure);
            }
        },
        onChangeDisplay(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.frame_display = e;
            this.set_configure(configure);
            this.resetFrameDisplay(e);
        },
        openCustomColor() {
            this.activeColor = this.configure.item_name_color;
            this.$refs.customColor.show();
        },
        customColorConfirm(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.item_name_color = e;
            this.set_configure(configure);
        },
        onBlurColor(e) {
            let color = e.target.value;
            var regular = /(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i;
            if (regular.test(color)) {
                let configure = this._.cloneDeep(this.configure);
                configure.item_name_color = '#' + color;
                this.set_configure(configure);
            } else {
                this.$notification['warning']({
                    message: 'Error Color Code',
                });
            }
        },
        choosePalette(color) {
            let configure = this._.cloneDeep(this.configure);
            configure.color_palette = this._.cloneDeep(color);
            this.set_configure(configure);
        },
        fontChange(font) {
            let configure = this._.cloneDeep(this.configure);
            for (let key in configure.font_family) {
                configure.font_family[key] = font;
            }
            this.set_configure(configure);
        },
        directionConfirm(e) {
            if (this.configure.category_layout == 'left') {
                this.$notification['error']({
                    message: 'Cannot switch view direction to portrait while using this layout.',
                    description: 'Change to another layout to switch direction.',
                });
                return;
            }
            let configure = this._.cloneDeep(this.configure);
            configure.direction = e;
            this.set_configure(configure);
        },
        openColor(index) {
            this.editColorIndex = index;
            this.activeColor = this.configure.color_palette['color' + index];
            this.$refs.paletteColor.show();
        },
        paletteColorConfirm(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.color_palette['color' + this.editColorIndex] = e;
            this.set_configure(configure);
        },
        layoutConfirm(e) {
            if (e == 'left') {
                this.set_page_left(0);
                this.switchLayoutLeft();
            }
            if (this.configure.category_layout == 'left') {
                this.$confirm({
                    title: 'Confirm',
                    content: 'Confirm to switch layout? All added categories and items will be lost.',
                    okText: 'Confirm',
                    cancelText: 'Cancel',
                    onOk: () => {
                        this.set_page(1);
                        this.emptyCategory();
                        let configure = this._.cloneDeep(this.configure);
                        configure.category_layout = e;
                        this.set_configure(configure);
                    },
                });
            } else {
                let configure = this._.cloneDeep(this.configure);
                configure.category_layout = e;
                this.set_configure(configure);
            }
        },
        savePalette() {
            this.setLoading({
                show_loading: true,
            });
            axiosApi
                .post(this.aj_host + '/weapp/admin/supermenu/update_palette', {
                    sid: this.sid,
                    palette: this.configure.color_palette,
                })
                .then((res) => {
                    this.setLoading({
                        show_loading: false,
                    });
                    if (res.data.data.code == 100000) {
                        this.$notification['success']({
                            message: 'Success',
                            description: 'Successfully!',
                        });
                        this.queryPalette();
                    } else {
                        this.$notification['error']({
                            message: 'Error',
                            description: res.data.data.msg,
                        });
                    }
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false,
                    });
                });
        },
        queryPalette() {
            axiosApi
                .get(this.aj_host + '/weapp/admin/supermenu/query_palette', {
                    params: {
                        sid: this.sid,
                    },
                })
                .then((res) => {
                    this.set_color_palette(res.data.data.data);
                });
        },
    },
});
</script>
<style scoped lang="less">
.middle {
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 30px 20px;
    width: 370px;
    background-color: #d6d6d6;
    .box {
        box-sizing: border-box;
        margin-bottom: 24px;
        padding: 20px 25px;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        .title {
            font-weight: bold;
            font-size: 20px;
        }
        .subhead {
            font-weight: 600;
        }
        .theme {
            display: flex;
            align-items: center;
            flex-direction: column;
            .theme-img {
                width: 148px;
                height: 148px;
                border: 4px solid #fff;
                border-radius: 50%;
            }
            .theme-name {
                position: relative;
                margin-top: -92px;
                font-size: 25px;
            }
            .theme-change {
                margin-top: 87px;
            }
        }
        .img-wrap {
            position: relative;
            margin-top: 20px;
            width: 100%;
            height: 150px;
            .change {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 150px;
            }
        }
        .palette {
            display: flex;
            justify-content: space-between;
            .color {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 80px;
                border-radius: 8px;
                cursor: pointer;
                .anticon-edit {
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 40px;
                }
            }
        }
        .color-list {
            margin-top: 20px;
            .item {
                display: flex;
                align-items: center;
                margin-bottom: 38px;
            }
            .color {
                width: 63px;
                height: 63px;
                background-color: #000;
            }
            .use {
                margin-left: auto;
                color: @main-color;
                text-decoration: underline;
                font-size: 18px;
                cursor: pointer;
            }
        }
        .font {
            .item {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                margin-bottom: 20px;
                font-weight: bold;
                font-size: 32px;
                .name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .use {
                    flex-basis: 60px;
                    color: @main-color;
                    text-align: right;
                    font-weight: normal;
                    font-size: 16px;
                    cursor: pointer;
                }
                .inuse {
                    flex-basis: 60px;
                    color: @info-color;
                    text-align: right;
                    font-weight: normal;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }
        .color-radio {
            display: block;
            display: flex;
            align-items: center;
            margin-bottom: 29px;
            font-size: 20px;
            .custom-color {
                margin-left: 20px;
                text-decoration: underline;
                text-shadow: pink 1px 1px 1px;
                font-size: 16px;
            }
        }
        .direction {
            display: flex;
            .vertical {
                flex-shrink: 0;
                width: 96px;
                height: 139px;
                border-radius: 7px;
                background-color: rgba(116, 116, 128, 0.08);
                text-align: center;
                line-height: 139px;
            }
            .horizontally {
                flex-shrink: 0;
                width: 139px;
                height: 96px;
                border-radius: 7px;
                background-color: rgba(116, 116, 128, 0.08);
                text-align: center;
                line-height: 96px;
            }
            .info {
                margin-left: 20px;
                .name {
                    font-weight: 500;
                    font-size: 20px;
                }
                .desc {
                    margin-top: 22px;
                    color: @info-color;
                }
            }
        }
        .layout-img {
            width: 248px;
            height: auto;
        }
    }
}
</style>
