<template>
    <div class="pagination">
        <div style="margin-bottom: 20px">Menu Preview</div>
        <div>
            Page{{ page }}
            <a-select v-model="currentPage" style="width: 100px; margin-left: 10px; color: #000" @change="onChangePage">
                <a-select-option :value="item" v-for="item in pageCount" :key="item"> {{ item }} </a-select-option>
            </a-select>
        </div>
        <div class="buttons">
            <div class="button prev" @click="previous">Previous</div>
            <div class="button next" @click="next">Next</div>
        </div>
        <div class="tip" v-if="configure.category_list.length == 0">*This is a sample page. Please add your own products in the next step.</div>
        <div class="category" v-if="editable == 2 || editable == 3">
            <div class="dividing"></div>
            <div class="title">Added Category</div>
            <div class="name" v-for="(item, index) in configure.category_list" :key="item.mid">{{ index + 1 }}.{{ menu_dict[item.mid].name }}</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'Pagination',
    props: {},
    data() {
        return {
            currentPage: '',
        };
    },

    async created() {},
    watch: {
        page: {
            handler: function (newValue) {
                this.currentPage = newValue;
                this.claen_operation_history();
            },
            immediate: true,
        },
    },
    mounted() {},
    computed: {
        ...mapGetters({
            pageCount: 'app/get_page_total',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            page: (state) => state.app.page,
            menu_dict: (state) => state.food.menu_dict,
            editable: (state) => state.app.editable,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            claen_operation_history: 'app/CLAEN_OPERATION_HISTORY',
        }),
        onChangePage(e) {
            this.$store.commit('app/SET_PAGE', e);
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$store.commit('app/SET_PAGE', this.currentPage);
            }
        },
        next() {
            if (this.currentPage < this.pageCount) {
                this.currentPage = this.currentPage + 1;
                this.$store.commit('app/SET_PAGE', this.currentPage);
            }
        },
    },
});
</script>
<style scoped lang="less">
.pagination {
    margin: 20px;
    width: 200px;
    color: #fff;
    font-size: 16px;
    .buttons {
        display: flex;
        margin-top: 30px;
        .button {
            margin-right: 50px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .tip {
        margin-top: 80px;
        padding: 10px 20px;
        border: 1px solid @main-color;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        color: #000000;
    }
    .category {
        margin-top: 80px;
        .dividing {
            height: 2px;
        }
        .title {
            margin-top: 20px;
            padding-left: 10px;
            font-size: 18px;
        }
        .name {
            margin-top: 10px;
            padding-left: 10px;
            font-size: 14px;
        }
    }
}
</style>
