<template>
    <a-modal class="milePop" title="Chef’s Choice" :width="400" v-model="visible" ok-text="Add this Category">
        <div class="count">12 items</div>
        <div class="dividing" style="margin: 20px 0 15px"></div>
        <div class="list">
            <div class="item" v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="item">
                <div class="name">Burger</div>
                <div class="price">$12.66</div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';

export default Vue.extend({
    name: 'CategoryView',
    props: {},
    data() {
        return {
            visible: false,
        };
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
    },
});
</script>
<style scoped lang="less">
.list {
    overflow-y: auto;
    height: 600px;
    .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 16px;
    }
}
</style>
