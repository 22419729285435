<template>
    <div class="features">
        <div class="name">{{ viewName[component_view] }} Preview</div>
        <a-button type="primary" @click="changeBackground" size="large" v-if="(component_view == 'nav' || component_view == 'cate') && configure.category_layout != 'left'">Edit Background</a-button>
        <a-button type="primary" @click="changeCover" size="large" v-if="component_view == 'cover'">Change Cover Image</a-button>
        <div class="desc" v-if="component_view == 'detail'">Current Style: {{ configure.item_detail_popup_style }}</div>
        <div class="style-box" v-if="component_view == 'detail'">
            <a-button type="primary" @click="changeItemDetail" size="large">Use {{ configure.item_detail_popup_style == 'small' ? 'Large' : 'Small' }} Style</a-button>
            <div class="dark">
                <a-checkbox :default-checked="configure.dark_mode_enable" @change="onChangeDark"> Use Dark Mode </a-checkbox>
            </div>
        </div>
        <!-- pop -->
        <background-view ref="background" :active="backgroundActive" :title="backgroundTitle" @confirm="backgroundConfirm"></background-view>
        <edit-background ref="cover" :active="coverActive" @confirm="coverConfirm"></edit-background>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';
import BackgroundView from '@components/pop/BackgroundView';
import EditBackground from '@components/pop/EditBackground';

export default Vue.extend({
    name: 'Preview',
    components: {
        BackgroundView,
        EditBackground,
    },
    props: {},
    data() {
        return {
            viewName: {
                nav: 'Navigation Bar',
                cate: 'Category Panel',
                cover: 'Menu Cover',
                detail: 'Item Detail Popup',
                cart: 'Cart',
                orders: 'Submitted Orders',
                language: 'Language Options',
                server: 'Call Server Panel',
            },
            backgroundActive: '',
            backgroundTitle: '',

            coverActive: '',
        };
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
            component_view: (state) => state.app.component_view,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
        }),
        changeBackground() {
            if (this.component_view == 'nav') {
                this.backgroundActive = this.configure.navigation_color;
                this.backgroundTitle = 'Navigation Bar Background';
            }
            if (this.component_view == 'cate') {
                this.backgroundActive = this.configure.category_color;
                this.backgroundTitle = 'Category Panel Background';
            }
            this.$refs.background.show();
        },
        backgroundConfirm(e) {
            let configure = this._.cloneDeep(this.configure);
            if (this.component_view == 'nav') {
                configure.navigation_color = e;
            }
            if (this.component_view == 'cate') {
                configure.category_color = e;
            }
            this.set_configure(configure);
        },
        changeItemDetail() {
            let configure = this._.cloneDeep(this.configure);
            if (configure.item_detail_popup_style == 'small') {
                configure.item_detail_popup_style = 'large';
            } else {
                configure.item_detail_popup_style = 'small';
            }
            this.set_configure(configure);
        },
        changeCover() {
            this.coverActive = this.configure.background_cover;
            this.$refs.cover.show();
        },
        coverConfirm(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.background_cover = e;
            this.set_configure(configure);
        },
        onChangeDark(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.dark_mode_enable = e.target.checked;
            this.set_configure(configure);
        },
    },
});
</script>
<style scoped lang="less">
.features {
    margin: 20px;
    width: 200px;
    color: #fff;
    font-size: 16px;
    .name {
        margin-bottom: 23px;
        // margin-left: 11px;
    }
    .desc {
        margin-bottom: 20px;
        font-size: 20px;
    }
    .style-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .dark {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            height: 40px;
            border-radius: 4px;
            background: #fff;
            font-size: 16px;
        }
    }
}
</style>
