<template>
    <a-modal class="milePop" title="Available Themes：" :width="800" v-model="visible" ok-text="Confirm" @ok="confirm">
        <div class="theme-box">
            <div :class="['item', activeTheme == item.theme_name ? 'active' : '']" v-for="(item, key) in THEME_LIST" :key="item.theme_name" @click="activeTheme = item.theme_name">
                <img class="theme-img" :style="{ borderColor: item.color_palette.color3 }" :src="item.background_cover[configure.direction]" alt="" />
                <div class="theme-name" :style="{ color: themeNameColor[key] }">{{ item.theme_name }}</div>
                <div class="selected">selected</div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';
import { THEME_LIST } from '@/utils/theme';

export default Vue.extend({
    name: 'ThemeChoose',
    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            THEME_LIST,
            visible: false,
            activeTheme: '',
            themeNameColor: {
                basic: '#000000',
                classic: '#C6A447',
                modern: '#ffffff',
            },
        };
    },
    watch: {
        active: {
            handler: function (newValue) {
                this.activeTheme = newValue;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        confirm() {
            this.$emit('confirm', this.activeTheme);
            this.visible = false;
        },
    },
});
</script>
<style scoped lang="less">
.theme-box {
    display: flex;
    .item {
        margin-right: 70px;
        text-align: center;
        cursor: pointer;
    }
    .theme-img {
        width: 189px;
        height: 189px;
        border: 4px solid #fff;
        border-radius: 50%;
    }
    .theme-name {
        position: relative;
        margin-top: -59%;
        color: #ddd;
        font-size: 25px;
    }
    .selected {
        display: none;
        margin-top: 90px;
        color: @main-color;
        font-weight: bold;
    }

    .active .theme-img {
        border-color: @main-color !important;
    }
    .active .selected {
        display: block;
    }
}
</style>
