<template>
    <div class="editor" v-if="JSON.stringify(menu_dict) != '{}'">
        <nav-preview v-if="editable == 2 || editable == 3"></nav-preview>
        <div class="empty" v-if="!pageSetting">Please add a category in the 【 Add Product 】tool bar.</div>
        <div class="main-area">
            <cate-preview-left v-if="editable == 2 || editable == 3"></cate-preview-left>
            <div :class="['operate-area', `grid${pageSetting.rows}`, pageSetting.image_display ? '' : 'no-image']">
                <div class="card" v-for="card in pageSetting && pageSetting.page.cards" :key="card.fid">
                    <div class="img">
                        <img class="adjustimg" :src="card.img_url || (card.fid ? foods_dict[card.fid].fpic || cover : '') || placeholder" alt="" />
                    </div>
                    <div class="info">
                        <div class="name">
                            {{ card.fid ? foods_dict[card.fid].fname : 'Food Name' }}
                        </div>
                        <div class="price" v-show="vertically">$19.99</div>
                        <div class="count" v-show="vertically">
                            <a-icon class="icon" :style="{ color: configure.color_palette.color3 }" type="minus-circle" theme="filled" />
                            <span class="count-num">0</span>
                            <a-icon class="icon" :style="{ color: configure.color_palette.color3 }" type="plus-circle" theme="filled" />
                        </div>
                        <div class="num" v-show="!vertically">
                            <div class="price">$19.99</div>
                            <div class="count">
                                <a-icon class="icon" :style="{ color: configure.color_palette.color3 }" type="minus-circle" theme="filled" />
                                <span class="count-num">0</span>
                                <a-icon class="icon" :style="{ color: configure.color_palette.color3 }" type="plus-circle" theme="filled" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- pop -->
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';
import NavPreview from '@components/common/NavPreview';
import CatePreviewLeft from '@components/common/CatePreviewLeft';

export default Vue.extend({
    name: 'Preview',
    components: {
        NavPreview,
        CatePreviewLeft,
    },
    props: {},
    data() {
        return {
            vertically: true,
            cover: require('@assets/images/menu_cover.png'),
        };
    },
    watch: {
        configure: {
            handler: function (newValue) {
                if (this._.isEmpty(newValue)) {
                    const token = window.localStorage.getItem('token') || '';
                    this.$router.push({ path: '/admin/index', query: { s: token } });
                }
            },
            immediate: true,
            deep: true,
        },
        pageSetting: {
            handler: function (newValue) {
                if ((newValue.rows == 2 || newValue.rows == 3) && !newValue.image_display) {
                    this.vertically = false;
                } else {
                    this.vertically = true;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            pageSetting: 'app/get_page_setting_left',
        }),
        ...mapState({
            menu_dict: (state) => state.food.menu_dict,
            configure: (state) => state.app.configure,
            foods_dict: (state) => state.food.foods_dict,
            editable: (state) => state.app.editable,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
        }),
    },
});
</script>
<style scoped lang="less">
.editor {
    background: #48484a;
    .empty {
        margin: 20px 0 0 20px;
        width: 100%;
        color: #fff;
        font-size: 18px;
    }
}
.main-area {
    display: flex;
    overflow-y: auto;
    height: 940px;
    &::-webkit-scrollbar {
        display: none;
    }
    .operate-area {
        display: grid;
        width: 710px;

        grid-gap: 4px 4px;
        .card {
            background: #fff;
            .img {
                flex-shrink: 0;
            }
            .info {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
                padding: 8px 16px;
                .name {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    max-height: 110px;
                    word-break: break-all;
                    font-size: 18px;

                    -webkit-line-clamp: 3;
                }
                .price {
                    font-size: 16px;
                }
                .count {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 auto;
                    min-width: 120px;
                    font-size: 32px;
                    .count-num {
                        font-size: 18px;
                    }
                }
                .num {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        &.grid2 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(auto-fill, 220px);
            .card {
                display: flex;
                height: 220px;
                .img {
                    overflow: hidden;
                    width: 55%;
                    height: 100%;
                }
            }
        }
        &.grid3 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: repeat(auto-fill, 320px);
            .card {
                display: flex;
                flex-direction: column;
                height: 320px;
                .img {
                    overflow: hidden;
                    width: 100%;
                    height: 180px;
                }
                .count {
                    min-width: 160px;
                }
            }
        }
        &.grid4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: repeat(auto-fill, 320px);
            .card {
                display: flex;
                flex-direction: column;
                height: 320px;
                .img {
                    overflow: hidden;
                    width: 100%;
                    height: 180px;
                }
            }
        }
        &.grid5 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: repeat(auto-fill, 320px);
            .card {
                display: flex;
                flex-direction: column;
                .img {
                    overflow: hidden;
                    width: 100%;
                    height: 180px;
                }
                .count {
                    min-width: 110px;
                }
            }
        }
        &.no-image {
            .card {
                .img {
                    display: none;
                }
                .info {
                    .name {
                        height: 80px;
                    }
                }
            }
            &.grid2 {
                grid-template-rows: repeat(auto-fill, 140px);
                .card {
                    height: 140px;
                    .count {
                        margin: 0;
                        min-width: 100px;
                    }
                }
            }
            &.grid3 {
                grid-template-rows: repeat(auto-fill, 140px);
                .card {
                    height: 140px;
                    .count {
                        margin: 0;
                        min-width: 100px;
                    }
                }
            }
            &.grid4 {
                grid-template-rows: repeat(auto-fill, 225px);
                .card {
                    height: 225px;
                }
            }
            &.grid5 {
                grid-template-rows: repeat(auto-fill, 225px);
                .card {
                    height: 225px;
                }
            }
        }
    }
}
</style>
