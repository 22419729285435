<template>
    <a-modal class="milePop" :title="title" :width="400" v-model="visible" ok-text="Confirm" @ok="confirm">
        <a-radio-group name="radioGroup" v-model="value">
            <a-radio value="filled"> Filled </a-radio>
            <a-radio value="transparent"> Transparent </a-radio>
        </a-radio-group>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'BackgroundView',
    props: {
        active: {
            type: String,
        },
        title: {
            type: String,
        },
    },
    data() {
        return {
            visible: false,
            value: '',
        };
    },
    watch: {
        active: {
            handler(newValue) {
                this.value = this.active;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        confirm() {
            this.$emit('confirm', this.value);
            this.visible = false;
        },
    },
});
</script>
<style scoped lang="less"></style>
