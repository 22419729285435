import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import createPersistedState from 'vuex-persistedstate';

import app from './modules/app';
import food from './modules/food';

Vue.use(Vuex);

let aj_host_admin;
if (window.location.hostname.indexOf('.net') > -1) {
    aj_host_admin = 'https://admin.minitable.net';
} else {
    aj_host_admin = 'https://admin.minitable.link';
}
const state = {
    debug: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost',
    aj_host: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost' ? 'http://127.0.0.1:7171' : '',
    aj_host_admin: aj_host_admin,
};

const getters = {};

state.is_development = state.debug;

export default new Vuex.Store({
    modules: {
        app,
        food,
    },
    state,
    getters,
    strict: state.debug,
    plugins: state.debug ? [createPersistedState(), createLogger()] : [createPersistedState()],
});
