<template>
    <div class="container">
        <div class="page-title">iPad Ordering</div>
        <div class="subtitle" style="margin-top: 34px">Basic Information</div>
        <div class="addbox">
            <div class="card create-item" @click="openStarter">
                <a-icon class="create-icon" type="plus-circle" />
                <div class="create-text">create a new menu</div>
            </div>
            <div class="add-item" v-for="item in menus" :key="item.smid">
                <div class="card">
                    <div class="name">{{ item.name }}</div>
                    <img :src="menuCover(item.configure.background_cover)" alt="" />
                    <div class="info">
                        <div class="type">{{ item.status == 1 ? 'Published' : 'Draft' }}</div>
                        <a-dropdown v-if="item.status == 0">
                            <div class="operate"><a-icon class="icon" type="ellipsis" /></div>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <div class="menu-item" @click="edit(item)">Edit</div>
                                </a-menu-item>
                                <a-menu-item>
                                    <div class="menu-item" @click="rename(item)">Rename</div>
                                </a-menu-item>
                                <a-menu-item>
                                    <div class="menu-item" @click="preview(item)">Preview</div>
                                </a-menu-item>
                                <a-menu-item>
                                    <div class="menu-item main-color" @click="publishMenu(item.smid)">Publish</div>
                                </a-menu-item>
                                <a-menu-item>
                                    <div class="menu-item" style="color: #ff3b30" @click="deleteMenu(item.smid)">Delete</div>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <div class="preview main-color" style="cursor: pointer" v-if="item.status == 1" @click="preview(item)">Preview</div>
                    </div>
                </div>
                <div class="updated">updated on {{ moment(Number(item.update_time)).tz(timezone).format('YYYY/MM/DD') }}</div>
            </div>
        </div>
        <div class="subtitle" style="margin-top: 34px">Menu Settings</div>
        <div class="setbox">
            <div class="setitem">
                <div class="label">Enable Table Selection</div>
                <a-switch v-model="table_selection_enable" @change="(e) => onChangeSwitch(e, 'table')" />
            </div>
            <div class="setitem">
                <div class="label">Enable Guest Phone Number Input</div>
                <a-switch v-model="phone_input_enable" @change="(e) => onChangeSwitch(e, 'phone')" />
            </div>
            <div class="dividing"></div>
            <div style="font-weight: 500; margin: 20px 0 0">Require Server Check for</div>
            <div class="setitem codeitem">
                <a-switch v-model="code_seat" @change="(e) => onChangeSwitch(e, 'seat')" />
                <div class="code-label">Seat party at table</div>
            </div>
            <div class="setitem codeitem">
                <a-switch v-model="code_settings" @change="(e) => onChangeSwitch(e, 'settings')" />
                <div class="code-label">Access settings page</div>
            </div>
            <div class="setitem codeitem">
                <a-switch v-model="code_change_table" @change="(e) => onChangeSwitch(e, 'change_table')" />
                <div class="code-label">Change default table on home page</div>
            </div>
            <div class="setitem codeitem">
                <a-switch v-model="code_new_party" @change="(e) => onChangeSwitch(e, 'new_party')" />
                <div class="code-label">Start New Party on menu page</div>
            </div>
            <div class="setitem codeitem">
                <a-switch v-model="code_first_order" @change="(e) => onChangeSwitch(e, 'first')" />
                <div class="code-label">Submit the first suborder</div>
            </div>
            <div class="setitem codeitem">
                <a-switch v-model="code_every_order" @change="(e) => onChangeSwitch(e, 'every')" />
                <div class="code-label">Submit each suborder</div>
            </div>
        </div>
        <div class="subtitle" style="margin-top: 34px">Display and Features</div>
        <div class="setbox">
            <div style="font-weight: 500; margin: 20px 0 0">Customized Order Completion Page</div>
            <div style="margin-left: 10px">
                <div style="font-weight: 500; margin: 10px 0">Image</div>
                <div class="flex">
                    <a-upload
                        name="file"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        accept="image/*"
                        reject="uploadReject"
                        :before-upload="(file) => beforeUpload(file)"
                    >
                        <a-button class="delete-btn" type="danger" v-if="completion_img_url" @click.stop="completion_img_url = ''"> Delete </a-button>
                        <img class="upload-block" v-if="completion_img_url" :src="completion_img_url" alt="avatar" />
                        <div v-if="!completion_img_url">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">Upload</div>
                        </div>
                    </a-upload>
                    <a-button type="primary" style="margin-bottom: 8px" @click="saveImg"> Save </a-button>
                    <a-button type="link" style="margin-bottom: 8px" @click="querySetting"> cancel </a-button>
                </div>
                <div style="font-weight: 500; margin: 10px 0">Text</div>
                <a-textarea v-model="completion_text" placeholder="" :auto-size="{ minRows: 3, maxRows: 5 }" :max-length="125" />
                <div class="suffix">125 char max</div>
                <div class="button-box">
                    <a-button type="primary" @click="saveText"> Save </a-button>
                    <a-button type="link" @click="querySetting"> cancel </a-button>
                </div>
            </div>
            <div class="dividing" style="margin: 30px 0"></div>
            <div style="font-weight: 500; margin: 20px 0 0">More settings</div>
            <div class="setitem codeitem">
                <a-switch v-model="display_subtotal_enable" @change="(e) => onChangeSwitch(e, 'display_subtotal')" />
                <div class="code-label">Display Subtotal Amount in [Cart] & [Submitted Orders]</div>
            </div>
            <div class="setitem codeitem">
                <a-switch v-model="tap_to_order_enable" @change="(e) => onChangeSwitch(e, 'tap_to_order')" />
                <div class="code-label">Display “tap to order” on cover page</div>
            </div>
            <div class="setitem codeitem">
                <a-switch v-model="quick_review_enable" @change="(e) => onChangeSwitch(e, 'quick_review')" />
                <div class="code-label">Enable Survey+ Quick Review</div>
            </div>
        </div>

        <div class="subtitle" style="margin-top: 34px">Menu Update Log</div>
        <a-table :columns="columns" :data-source="logs" rowKey="id"></a-table>
        <!-- pop -->
        <starter ref="starter"></starter>
        <rename ref="rename" :active="chnageName" @confirm="renameConfirm"></rename>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Starter from '@components/common/Starter';
import Rename from '@components/pop/Rename';
import axiosApi from '@kits/axiosApi';
import moment from 'moment-timezone';

export default {
    name: 'Index',
    components: {
        Starter,
        Rename,
    },
    data() {
        return {
            moment,
            cover: require('@assets/images/menu_cover.png'),
            aj_host: this.$store.state.aj_host,
            list: [
                {
                    date: '2022/01/01',
                    time: '12:00',
                    version: '1.0',
                },
            ],
            columns: [
                {
                    title: 'Date Time',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    customRender: (text, row, index) => {
                        if (text) {
                            return moment(Number(text)).tz(this.timezone).format('YYYY/MM/DD HH:mm');
                        }
                        return '-';
                    },
                },
                {
                    title: 'Version',
                    dataIndex: 'version',
                    key: 'version',
                },
                {
                    title: 'Published Draft',
                    dataIndex: 'name',
                    key: 'name',
                },
            ],
            logs: [],
            menus: [],
            table_selection_enable: false,
            phone_input_enable: false,
            code_seat: false,
            code_settings: false,
            code_change_table: false,
            code_new_party: false,
            code_first_order: false,
            code_every_order: false,
            display_subtotal_enable: false,
            tap_to_order_enable: false,
            quick_review_enable: false,

            chnageName: '',
            chnageSmid: '',

            completion_img_url: '',
            completion_text: '',
        };
    },
    beforeMount() {},
    updated() {},
    beforeCreate() {},
    async created() {
        let token = this.$route.query.s || '';
        window.localStorage.setItem('token', token);

        let storeData = await this.initStore();
        if (storeData.code != 100000) {
            this.$notification['warning']({
                message: storeData.msg,
            });
            return;
        }
        this.queryList();
        this.querySetting();
        this.queryLog();
    },

    watch: {},
    computed: {
        ...mapGetters({
            sid: 'app/get_sid',
            timezone: 'app/get_timezone',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
        }),
        menuCover() {
            return (src) => {
                if (src.indexOf('#') == 0) {
                    return this.cover;
                } else {
                    return src;
                }
            };
        },
    },
    methods: {
        ...mapActions({
            initStore: 'app/initStoreData',
        }),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING',
            set_configure: 'app/SET_CONFIGURE',
            set_editable: 'app/SET_EDITABLE',
            set_color_palette: 'app/SET_COLOR_PALETTE',
            set_tap_to_order: 'app/SET_TAP_TO_ORDER',
            set_page: 'app/SET_PAGE',
            set_page_left: 'app/SET_PAGE_LEFT',
        }),
        openStarter() {
            this.$refs.starter.show();
        },
        preview(item) {
            this.set_editable(2);
            item.configure.smid = item.smid;
            if (typeof item.configure.frame_display == 'undefined') {
                item.configure.frame_display = true;
            }
            if (typeof item.configure.image_all == 'undefined') {
                item.configure.image_all = item.configure.background_cover;
                item.configure.image_all_enable = false;
            }
            if (typeof item.configure.item_detail_popup_style == 'undefined') {
                item.configure.item_detail_popup_style = 'small';
            }
            if (typeof item.configure.dark_mode_enable == 'undefined') {
                item.configure.dark_mode_enable = false;
            }
            this.set_configure(this.formatConfigureXY(item.configure));
            this.set_page(1);
            this.set_page_left(0);
            this.$router.push({ path: '/admin/editor' });
        },
        rename(item) {
            this.chnageName = item.name;
            this.chnageSmid = item.smid;
            this.$refs.rename.show();
        },
        renameConfirm(e) {
            this.setLoading({
                show_loading: true,
            });
            axiosApi
                .post(this.aj_host + '/weapp/admin/v2/supermenu/update_menu_name', {
                    smid: this.chnageSmid,
                    name: e,
                })
                .then((res) => {
                    this.queryList();
                    this.queryLog();
                    this.$notification['success']({
                        message: 'Success',
                        description: 'Successfully!',
                    });
                    this.setLoading({
                        show_loading: false,
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false,
                    });
                });
        },
        edit(item) {
            this.set_editable(1);
            item.configure.smid = item.smid;
            if (typeof item.configure.frame_display == 'undefined') {
                item.configure.frame_display = true;
            }
            if (typeof item.configure.image_all == 'undefined') {
                item.configure.image_all = item.configure.background_cover;
                item.configure.image_all_enable = false;
            }
            if (typeof item.configure.item_detail_popup_style == 'undefined') {
                item.configure.item_detail_popup_style = 'small';
            }
            if (typeof item.configure.dark_mode_enable == 'undefined') {
                item.configure.dark_mode_enable = false;
            }
            this.set_configure(this.formatConfigureXY(item.configure));
            this.set_page(1);
            this.$router.push({ path: '/admin/editor' });
        },
        publishMenu(smid) {
            this.$confirm({
                title: 'Warning',
                content: 'Menu will be updated immediately.',
                okText: 'Publish',
                cancelText: 'Cancel',
                onOk: () => {
                    this.updateStatusAj(smid, 1);
                },
            });
        },
        deleteMenu(smid) {
            this.$confirm({
                title: 'Warning',
                content: 'This action can’t be undone.',
                okText: 'Delete',
                cancelText: 'Cancel',
                onOk: () => {
                    this.updateStatusAj(smid, 2);
                },
            });
        },
        onChangeSwitch(e, type) {
            let postData = {
                sid: this.sid,
            };
            if (type == 'table') {
                postData.table_selection_enable = e ? 1 : 0;
            }
            if (type == 'phone') {
                postData.phone_input_enable = e ? 1 : 0;
            }
            if (type == 'seat') {
                postData.code_seat = e ? 1 : 0;
            }
            if (type == 'settings') {
                postData.code_settings = e ? 1 : 0;
            }
            if (type == 'change_table') {
                postData.code_change_table = e ? 1 : 0;
            }
            if (type == 'new_party') {
                postData.code_new_party = e ? 1 : 0;
            }
            if (type == 'first') {
                postData.code_first_order = e ? 1 : 0;
            }
            if (type == 'every') {
                postData.code_every_order = e ? 1 : 0;
            }
            if (type == 'display_subtotal') {
                postData.display_subtotal_enable = e ? 1 : 0;
            }
            if (type == 'tap_to_order') {
                postData.tap_to_order_enable = e ? 1 : 0;
                this.set_tap_to_order(this.tap_to_order_enable);
            }
            if (type == 'quick_review') {
                postData.quick_review_enable = e ? 1 : 0;
            }
            axiosApi
                .post(this.aj_host + '/weapp/admin/supermenu/update_setting', postData)
                .then((res) => {
                    this.$notification['success']({
                        message: 'Success',
                        description: 'Successfully!',
                    });
                })
                .catch((err) => {});
        },
        queryList() {
            this.setLoading({
                show_loading: true,
            });
            axiosApi
                .get(this.aj_host + '/weapp/admin/v2/supermenu/query_list', {
                    params: {
                        sid: this.sid,
                    },
                })
                .then((res) => {
                    this.menus = res.data.data.data.menus;
                    this.setLoading({
                        show_loading: false,
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false,
                    });
                });
        },
        querySetting() {
            this.setLoading({
                show_loading: true,
            });
            axiosApi
                .get(this.aj_host + '/weapp/admin/supermenu/query_setting', {
                    params: {
                        sid: this.sid,
                    },
                })
                .then((res) => {
                    let setting = res.data.data.data.setting;
                    this.table_selection_enable = setting.table_selection_enable == 1 ? true : false;
                    this.phone_input_enable = setting.phone_input_enable == 1 ? true : false;
                    this.code_seat = setting.code_seat == 1 ? true : false;
                    this.code_settings = setting.code_settings == 1 ? true : false;
                    this.code_change_table = setting.code_change_table == 1 ? true : false;
                    this.code_new_party = setting.code_new_party == 1 ? true : false;
                    this.code_first_order = setting.code_first_order == 1 ? true : false;
                    this.code_every_order = setting.code_every_order == 1 ? true : false;
                    this.display_subtotal_enable = setting.display_subtotal_enable == 1 ? true : false;
                    this.tap_to_order_enable = setting.tap_to_order_enable == 1 ? true : false;
                    this.quick_review_enable = setting.quick_review_enable == 1 ? true : false;

                    this.completion_img_url = setting.completion_img_url;
                    this.completion_text = setting.completion_text;

                    this.set_tap_to_order(this.tap_to_order_enable);
                    this.set_color_palette(setting.palettes);
                    this.setLoading({
                        show_loading: false,
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false,
                    });
                });
        },
        queryLog() {
            axiosApi
                .get(this.aj_host + '/weapp/admin/v2/supermenu/query_log', {
                    params: {
                        sid: this.sid,
                    },
                })
                .then((res) => {
                    this.logs = res.data.data.data.logs;
                })
                .catch((err) => {});
        },
        updateStatusAj(smid, status) {
            axiosApi
                .post(this.aj_host + '/weapp/admin/v2/supermenu/update_menu_status', {
                    sid: this.sid,
                    smid,
                    status,
                })
                .then((res) => {
                    this.queryList();
                    this.queryLog();
                    this.$notification['success']({
                        message: 'Success',
                        description: 'Successfully!',
                    });
                })
                .catch((err) => {});
        },
        formatConfigureXY(configure) {
            let configureSelf = this._.cloneDeep(configure);
            let width = configureSelf.direction == 'vertical' ? '860' : '1050';
            let height = configureSelf.direction == 'vertical' ? '780' : '600';
            for (let i = 0; i < configureSelf.category_list.length; i++) {
                const cate = configureSelf.category_list[i];
                for (let j = 0; j < cate.page_list.length; j++) {
                    const page = cate.page_list[j];
                    for (let k = 0; k < page.cards.length; k++) {
                        const card = page.cards[k];
                        card.x = Number((card.x * width).toFixed(0));
                        card.y = Number((card.y * height).toFixed(0));
                    }
                }
            }
            return configureSelf;
        },
        uploadReject() {
            this.$notification['warning']({
                message: 'Errot type',
                description: 'Only supports image upload.',
            });
        },
        async beforeUpload(file) {
            if (file.size > 10485760) {
                this.$notification['warning']({
                    message: 'Image size cannot exceed 10MB',
                });
                return false;
            }
            var formData = new FormData();
            formData.append('file', file);
            let time = Date.parse(new Date());
            var randomNum = '';
            for (var i = 0; i < 4; i++) {
                var a = Math.floor(Math.random() * 9);
                randomNum += a;
            }
            formData.append('name', this.sid + '_super_menu_img_' + time + randomNum);
            await this.uploadImg(formData).then((res) => {
                this.completion_img_url = res.file_url;
            });
            return false;
        },
        uploadImg(params) {
            return new Promise((resolve, reject) => {
                this.setLoading({
                    show_loading: true,
                });
                axiosApi
                    .post(this.aj_host + '/weapp/admin/supermenu/upload', params, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((res) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        resolve(res.data.data.data);
                    })
                    .catch((err) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        reject(err);
                    });
            });
        },
        saveImg() {
            this.setLoading({
                show_loading: true,
            });
            axiosApi
                .post(this.aj_host + '/weapp/admin/supermenu/completion_img', {
                    sid: this.sid,
                    completion_img_url: this.completion_img_url,
                })
                .then((res) => {
                    if (res.data.data.code == 100000) {
                        this.$notification['success']({
                            message: 'Success',
                            description: 'Successfully!',
                        });
                    } else {
                        this.$notification['error']({
                            message: 'Error',
                            description: res.data.data.msg,
                        });
                    }
                    this.setLoading({
                        show_loading: false,
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false,
                    });
                });
        },
        saveText() {
            this.setLoading({
                show_loading: true,
            });
            axiosApi
                .post(this.aj_host + '/weapp/admin/supermenu/completion_text', {
                    sid: this.sid,
                    completion_text: this.completion_text,
                })
                .then((res) => {
                    if (res.data.data.code == 100000) {
                        this.$notification['success']({
                            message: 'Success',
                            description: 'Successfully!',
                        });
                    } else {
                        this.$notification['error']({
                            message: 'Error',
                            description: res.data.data.msg,
                        });
                    }
                    this.setLoading({
                        show_loading: false,
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false,
                    });
                });
        },
    },
};
</script>

<style scoped lang="less">
.addbox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 34px;

    .card {
        margin-right: 59px;
        padding: 23px;
        width: 294px;
        height: 294px;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        .name {
            overflow: hidden;
            margin-top: 15px;
            margin-bottom: 23px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            font-size: 18px;
        }
    }
    .create-item {
        text-align: center;
        cursor: pointer;
        .create-icon {
            margin-top: 40px;
            color: #979797;
            font-size: 122px;
        }
        .create-text {
            margin-top: 30px;
            font-weight: bold;
        }
    }
    .add-item {
        img {
            width: 248px;
            height: 148px;
            cursor: pointer;
        }
        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
        }
        .type {
            font-weight: bold;
        }
        .icon {
            color: @main-color;
            font-size: 24px;
            cursor: pointer;
        }
        .updated {
            margin-top: 23px;
            color: @info-color;
        }
    }
}
.setbox {
    padding-left: 22px;
    .setitem {
        display: flex;
        margin: 23px 0;
        .label {
            margin-right: 33px;
        }
        .code-label {
            margin-left: 20px;
        }
    }
    .codeitem {
        margin-left: 10px;
    }
    /deep/ .ant-upload.ant-upload-select-picture-card {
        width: 265px;
        height: 150px;
    }
    /deep/ .upload-block {
        width: 100%;
    }
    /deep/ .ant-upload-picture-card-wrapper {
        width: auto;
    }
    /deep/ .ant-upload {
        position: relative;
    }

    .flex {
        display: flex;
        align-items: end;
    }
    .suffix {
        position: relative;
        z-index: 18;
        margin: -20px 12px 0 0;
        color: #8f8f8f;
        text-align: right;
    }
    .button-box {
        margin-top: 10px;
        text-align: right;
    }
    .delete-btn {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100px;
    }
}
</style>
