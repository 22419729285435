<template>
    <a-modal class="milePop" title="Enter Text and Select Color" :width="800" v-model="visible" ok-text="Confirm" @ok="confirm">
        <div class="delete">
            <a-button type="danger" @click="handleDelete"> Delete Card </a-button>
        </div>
        <a-textarea v-model="currentCard.text" placeholder="" :auto-size="{ minRows: 5, maxRows: 10 }" :max-length="500" />

        <div class="color">
            <span>Text Color: </span>
            <span class="color-code" @click="showColor" :style="{ color: currentCard.text_color }">{{ currentCard.text_color }}</span>
        </div>
        <div style="margin-top: 30px">Preview:</div>
        <div ref="previewText" class="preview-text" :style="{ color: currentCard.text_color }">
            {{ currentCard.text }}
        </div>
        <!-- pop -->
        <color-picker ref="color" :active="activeColor" @confirm="colorConfirm"></color-picker>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';
import ColorPicker from '@components/pop/ColorPicker';

export default Vue.extend({
    name: 'ParagraphCardEdit',
    components: {
        ColorPicker,
    },
    props: {
        card: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            visible: false,
            currentCard: {},
            activeColor: '',
        };
    },
    watch: {
        card: {
            handler(newValue) {
                this.currentCard = this._.cloneDeep(newValue);
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        handleDelete() {
            this.$confirm({
                title: 'Warning',
                content: 'Card and the item contained will be removed.',
                okText: 'Delete Card',
                cancelText: 'Cancel',
                onOk: () => {
                    this.visible = false;
                    this.$emit('deletecard');
                },
            });
        },
        showColor() {
            this.activeColor = this.currentCard.text_color;
            this.$refs.color.show();
        },
        colorConfirm(e) {
            this.currentCard.text_color = e;
        },
        confirm() {
            this.$emit('confirm', {
                text: this.currentCard.text,
                text_color: this.currentCard.text_color,
                height: this.$refs.previewText.offsetHeight,
            });
            this.visible = false;
        },
    },
});
</script>
<style scoped lang="less">
.delete {
    margin-bottom: 30px;
    text-align: right;
}
.color {
    margin-top: 30px;
    .color-code {
        margin-left: 10px;
        text-decoration: underline;
        cursor: pointer;
    }
}
.preview-text {
    padding: 20px;
    width: 340px;
    border: 1px dashed #d6d6d6;
    word-break: break-all;
    font-weight: 500;
    font-size: 18px;
}
</style>
