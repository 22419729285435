<template>
    <a-modal class="milePop" title="Processing Material" :width="800" v-model="visible" ok-text="Confirm" :footer="null" :force-render="true" :z-index="0">
        <a-button @click="handleImg">handleImg</a-button>
        <span
            id="img-border"
            :class="['border', 'iconfont', `icon-img${configure.theme_name}`]"
            :style="{ color: configure.color_palette.color1, fontSize: 204 / ratio + 'px', width: 204 / ratio + 'px', height: 204 / ratio + 'px' }"
        ></span>
        <span id="price-border" :class="['price-border', 'iconfont', `icon-${configure.theme_name}-border`]" :style="{ color: configure.color_palette.color1, fontSize: 80 / ratio + 'px' }"></span>
        <span id="price-bg" :class="['price-bg', 'iconfont', `icon-${configure.theme_name}-fill`]" :style="{ color: configure.color_palette.color3, fontSize: 80 / ratio + 'px' }"></span>
        <span id="category-icon" :class="['cate-icon', 'iconfont', `icon-cate${configure.theme_name}`]" :style="{ color: configure.color_palette.color2, fontSize: 58 / ratio + 'px' }"></span>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import html2canvas from 'html2canvas';

export default Vue.extend({
    name: 'UploadBorderImg',
    props: {},
    data() {
        return {
            visible: false,
            imageBorder: '',
            priceBorder: '',
            priceBackground: '',
            categoryIcon: '',
            ratio: 1,
        };
    },
    watch: {
        visible: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.handleImg();
                    });
                }
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {
        this.getRatio();
    },
    computed: {
        ...mapState({
            aj_host: (state) => state.aj_host,
            configure: (state) => state.app.configure,
        }),
        ...mapGetters({
            sid: 'app/get_sid',
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        handleImg() {
            this.getRatio();
            let a = this.uploadImgFun('imageBorder', '#img-border');
            let b = this.uploadImgFun('priceBorder', '#price-border');
            let c = this.uploadImgFun('priceBackground', '#price-bg');
            let d = this.uploadImgFun('categoryIcon', '#category-icon');

            Promise.all([a, b, c, d]).then((result) => {
                this.$emit('confirm', {
                    imageBorder: this.imageBorder,
                    priceBorder: this.priceBorder,
                    priceBackground: this.priceBackground,
                    categoryIcon: this.categoryIcon,
                });
                this.visible = false;
            });
        },
        /**
         * 上传图片
         */
        uploadImgFun(keyName, idName) {
            return new Promise((resolve, reject) => {
                html2canvas(document.querySelector(idName), {
                    backgroundColor: null,
                    y: 1,
                }).then((canvas) => {
                    canvas.toBlob(async (blob) => {
                        let file = new File([blob], 'png', { type: 'image/png' });
                        await this.beforeUpload(file, keyName);
                        resolve();
                    });
                });
            });
        },
        async beforeUpload(file, keyName) {
            if (file.size > 5242880) {
                this.$notification['warning']({
                    message: 'Image size cannot exceed 5MB',
                });
                return false;
            }
            var formData = new FormData();
            formData.append('file', file);
            let time = Date.parse(new Date());
            var randomNum = '';
            for (var i = 0; i < 4; i++) {
                var a = Math.floor(Math.random() * 9);
                randomNum += a;
            }
            formData.append('name', this.sid + '_super_menu_img_' + time + randomNum);
            await this.uploadImg(formData).then((res) => {
                this[keyName] = res.file_url;
            });
            return false;
        },
        uploadImg(params) {
            return new Promise((resolve, reject) => {
                // this.setLoading({
                //     show_loading: true,
                // });
                axiosApi
                    .post(this.aj_host + '/weapp/admin/supermenu/upload', params, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((res) => {
                        // this.setLoading({
                        //     show_loading: false,
                        // });
                        resolve(res.data.data.data);
                    })
                    .catch((err) => {
                        // this.setLoading({
                        //     show_loading: false,
                        // });
                        reject(err);
                    });
            });
        },
        // 获取屏幕缩放比例
        getRatio() {
            var ratio = 0;
            var screen = window.screen;
            var navi = navigator.userAgent.toLowerCase();
            if (window.devicePixelRatio !== undefined) {
                ratio = window.devicePixelRatio;
            } else if (~navi.indexOf('msie')) {
                if (screen.deviceXDPI && screen.logicalXDPI) {
                    ratio = screen.deviceXDPI / screen.logicalXDPI;
                }
            } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
                ratio = window.outerWidth / window.innerWidth;
            }
            if (ratio) {
                ratio = Math.round(ratio);
            }
            this.ratio = ratio;
        },
    },
});
</script>
<style scoped lang="less">
// .border {
//     font-size: 102px;
// }
// .price-border {
//     font-size: 42px;
// }
// .price-bg {
//     font-size: 40px;
// }
// .cate-icon {
//     font-size: 29px;
// }
.border {
    display: flex;
    align-items: center;
}
.milePop /deep/ .ant-modal-body {
    overflow-y: auto;
    height: 200px;
}
</style>
