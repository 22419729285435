module.exports = {
    /**
     * layout类型列表
     */
    LAYOUT_TYPE_LIST: {
        vertical: [11, 21, 31, 41, 42, 51, 61, 81],
        horizontal: [12, 22, 32, 43, 44, 52, 62, 82],
    },
    /**
     * card类型尺寸
     */
    CARD_SIZE: {
        card11: { width: 330, height: 180 },
        card12: { width: 180, height: 300 },
        card21: { width: 500, height: 245 },
        card22: { width: 300, height: 375 },
        card31: { width: 760, height: 440 },
        card32: { width: 560, height: 570 },
        card41: { width: 780, height: 580 },
        card42: { width: 580, height: 716 },
        card51: { width: 360, height: 65 },
        card61: { width: 340, height: 190 },
    },

    /**
     * 字体名称
     * 字体值和字体名称相同
     * 设置文件 /assets/styles/font.less
     * 字体存放文件 /assets/font
     */
    FONT_LIST: ['Roboto', 'Merriweather', 'Rubik', 'Noto Serif', 'Lora', 'Oriya MN'],

    /**
     * 新建配置
     */
    CONFIGURE: {
        name: '',
        theme_name: '',
        border_img: '',
        quantity_img: '',
        category_img: '',
        color_palette: {},
        font_family: { store_name: 'Oriya MN', category_name: 'Oriya MN', item_name: 'Oriya MN', quantity: 'Oriya MN', price: 'Oriya MN', paragraph: 'Oriya MN', cover: 'Oriya MN' },
        item_name_color: '',
        direction: 'vertical',
        category_layout: 'top',
        background_cover: '',
        category_list: [],
        navigation_color: 'filled',
        category_color: 'filled',
        call_server_display: 1,
        frame_display: true,
        image_all: '',
        image_all_enable: false,
        item_detail_popup_style: 'small',
        dark_mode_enable: false,
    },
};
