<template>
    <a-modal class="milePop" title="Would you like the menu vertical or horizontal?" :width="800" v-model="visible" ok-text="Confirm" @ok="confirm">
        <div class="direction">
            <div :class="['item', selected == 'vertical' ? 'active' : '']" @click="selected = 'vertical'">
                <div class="cube vertical"></div>
                <div class="text">vertical</div>
            </div>
            <div :class="['item', selected == 'horizontal' ? 'active' : '']" @click="selected = 'horizontal'">
                <div class="cube horizontal"></div>
                <div class="text">horizontal</div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'DirectionChoose',
    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: false,
            selected: '',
        };
    },
    watch: {
        active: {
            handler(newValue, oldValue) {
                this.selected = newValue;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        confirm() {
            this.visible = false;
            this.$emit('confirm', this.selected);
        },
    },
});
</script>
<style scoped lang="less">
.direction {
    display: flex;
    margin-top: 30px;
    .item {
        display: flex;
        align-items: center;
        margin-right: 100px;
        cursor: pointer;
    }
    .item.active .cube {
        border-color: @main-color;
    }
    .item.active .text {
        color: @main-color;
    }
    .cube {
        border: 3px solid #fff;
        background-color: #d8d8d8;
    }
    .vertical {
        width: 68px;
        height: 98px;
    }
    .horizontal {
        width: 98px;
        height: 68px;
    }
    .text {
        margin-left: 16px;
    }
}
</style>
