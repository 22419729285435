<template>
    <a-modal class="milePop" :title="menu_dict[active].name + ' / Added Pages'" :width="800" v-model="visible" v-if="active">
        <div class="pages">
            <div class="item" v-for="(item, index) in categorySetting(active) ? categorySetting(active).page_list : []" :key="index">
                <div class="global-page">p.{{ item.pageNo + 1 }}</div>
                <a-icon class="sort up" type="up-circle" @click="sort(index, 'up')" />
                <a-icon class="sort down" type="down-circle" @click="sort(index, 'down')" />
                <div class="page">{{ index + 1 }}</div>
                <div class="name">
                    <span class="name-span" v-for="(food, foodIndex) in item.cards" :key="food.fid + foodIndex">
                        {{ food.fid ? foods_dict[food.fid].fname : 'Empty Food' }}
                        <span class="comma">,</span>
                    </span>
                </div>
                <a-button class="goto" type="link" @click="gotoPage(item.pageNo)"> Go to this page </a-button>
                <a-icon class="delete" type="delete" @click="remove(index)" />
            </div>
        </div>
        <template slot="footer">
            <a-button key="confirm" type="primary" @click="confirm"> Close </a-button>
        </template>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'CategoryEdit',
    components: {},

    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            categorySetting: 'app/get_category_setting',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            menu_dict: (state) => state.food.menu_dict,
            foods_dict: (state) => state.food.foods_dict,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_configure: 'app/SET_CONFIGURE',
            set_page: 'app/SET_PAGE',
        }),

        show() {
            this.visible = true;
        },
        gotoPage(index) {
            this.$store.commit('app/SET_PAGE', index + 1);
            this.visible = false;
        },
        remove(index) {
            this.$confirm({
                title: 'Warning',
                content: 'Unsaved edits will be lost.',
                okText: 'Remove',
                cancelText: 'Cancel',
                onOk: () => {
                    let configure = this._.cloneDeep(this.configure);
                    for (let i = 0; i < configure.category_list.length; i++) {
                        const cate = configure.category_list[i];
                        if (cate.mid == this.active) {
                            cate.page_list.splice(index, 1);
                            break;
                        }
                    }
                    this.set_configure(configure);
                    this.set_page(1);
                },
            });
        },
        sort(index, type) {
            if (index == 0 && type == 'up') {
                return;
            }
            if (index == this.categorySetting(this.active).page_list.length - 1 && type == 'down') {
                return;
            }
            let increase = type == 'up' ? -1 : 1;
            let configure = this._.cloneDeep(this.configure);
            for (let i = 0; i < configure.category_list.length; i++) {
                const cate = configure.category_list[i];
                if (cate.mid == this.active) {
                    cate.page_list[index] = cate.page_list.splice(index + increase, 1, cate.page_list[index])[0];
                    break;
                }
            }
            this.set_configure(configure);
        },
        confirm() {
            this.visible = false;
        },
    },
});
</script>
<style scoped lang="less">
.item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    .global-page {
        flex-shrink: 0;
        margin-right: 20px;
        width: 40px;
        color: @info-color;
    }
    .page {
        margin-right: 10px;
        color: @main-color;
    }
    .sort {
        color: @main-color;
        &.up {
            margin-right: 5px;
        }
        &.down {
            margin-right: 20px;
        }
    }
    .goto {
        margin-left: auto;
    }
    .delete {
        color: #ff3b30;
    }
    .anticon {
        font-size: 20px;
        cursor: pointer;
    }
    .name {
        word-break: break-all;
        .name-span {
            margin-right: 10px;
        }
        .name-span:last-child {
            .comma {
                display: none;
            }
        }
    }
}
</style>
