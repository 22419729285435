<template>
    <div
        :class="['features', configure.direction]"
        :style="{
            width: configure.direction == 'vertical' ? '686px' : '913px',
            height: configure.direction == 'vertical' ? '913px' : '686px',
            ...bgClass,
        }"
    >
        <nav-preview v-show="component_view == 'nav'"></nav-preview>
        <cate-preview v-show="component_view == 'cate' && configure.category_layout != 'left'"></cate-preview>
        <cate-preview-left v-show="component_view == 'cate' && configure.category_layout == 'left'"></cate-preview-left>

        <div class="cover" v-show="component_view == 'cover'">
            <div class="top" :style="{ color: configure.color_palette.color2, fontFamily: configure.font_family.cover }">Lobster and Burger Cafe</div>
            <div class="center" :style="{ color: configure.color_palette.color1, fontFamily: configure.font_family.cover }" v-show="tap_to_order">tap to order</div>
            <div class="bottom" :style="{ color: configure.color_palette.color2, fontFamily: configure.font_family.cover }">Welcome</div>
        </div>
        <div :class="['detail', configure.item_detail_popup_style == 'large' ? 'large' : '', configure.direction == 'vertical' ? 'vertical' : 'horizontal']" v-show="component_view == 'detail'">
            <div class="touch-handle"></div>
            <div class="close"><a-icon type="close" :style="{ color: configure.color_palette.color3 }" /></div>
            <div class="limit" :style="{ backgroundColor: configure.color_palette.color3 }">limit 999 per order</div>
            <div class="img-wrap">
                <img class="timg" src="../../assets/images/chicken.jpg" alt="" />
            </div>
            <div class="content">
                <div class="food-name">Sample Item Detail Page</div>
                <div class="price">$10</div>
                <div style="margin-top: 16px">
                    <img src="../../assets/images/leaf.png" alt="" style="width: 15px; height: 16px" />
                    <span style="color: #419f5a; margin-left: 8px; margin-right: 12px; font-weight: 500">Vegan</span>
                    <img src="../../assets/images/warn.png" alt="" style="width: 18px; height: 18px" />
                    <span style="color: #e90112; margin-left: 8px; font-weight: 500">Contains peanut</span>
                </div>
                <div style="margin-top: 7px; color: #626262; font-size: 14px">
                    Served with a dipping sauce and vanilla mascarpone. You can also adjust the sweetness level. Or we can make it salty.
                </div>
                <div class="dividing" style="margin: 24px 0 24px"></div>
                <div class="addon">
                    <div class="addon-desc">
                        <div style="font-weight: 600">Omelette Egg <span style="font-size: 14px; font-weight: 300">(Required)</span></div>
                        <div style="font-weight: 300; font-size: 14px; color: #7e7e7e; margin-top: 6px">Choose 1</div>
                    </div>
                    <div class="addon-item">
                        <a-checkbox style="font-size: 16px; font-weight: 500"> Standard </a-checkbox>
                        <span>$ 0</span>
                    </div>
                    <div class="addon-item">
                        <a-checkbox style="font-size: 16px; font-weight: 500"> Egg White </a-checkbox>
                        <span>$ 0</span>
                    </div>
                </div>
                <div style="font-weight: 700">Special Instructions</div>
                <div class="special">Dressing on the side? No pickles? Let us know.</div>

                <div class="count">
                    <div class="steper">
                        <div class="add">-</div>
                        <div style="font-size: 20px; font-weight: 500; margin: 0 15px">1</div>
                        <div class="add" style="margin-right: 37px">+</div>
                    </div>
                    <div class="button" :style="{ backgroundColor: configure.color_palette.color3 }">
                        <span></span>
                        <span>Add 1 to Cart</span>
                        <span>$ 16.49</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart" v-show="component_view == 'cart'">
            <div class="title">Items in Cart (12)</div>
            <div class="dividing" style="margin: 14px 0 23px"></div>
            <div class="order">
                <div class="category" :style="{ color: configure.color_palette.color3 }">Chef’s Choice</div>
                <div class="food-wrap">
                    <img class="food-img" src="../../assets/images/food.png" alt="" />
                    <div style="flex: 1">
                        <div class="food">
                            <div class="name">Pizza Margerita</div>
                            <div class="price">$12</div>
                            <div class="count">
                                <a-icon type="minus-circle" theme="filled" :style="{ color: configure.color_palette.color3 }" />
                                <span style="margin: 0 11px">1</span>
                                <a-icon type="plus-circle" theme="filled" :style="{ color: configure.color_palette.color3 }" />
                            </div>
                        </div>
                        <div class="options">
                            <div class="item">(option1)</div>
                            <div class="item">
                                <div class="name">addon name *1</div>
                                <div class="price">($1.3)</div>
                            </div>
                            <div class="item">
                                <div class="name">addon name *1</div>
                                <div class="price">($1.3)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dividing bottom-line" style="margin: 24px 0 20px"></div>
            <div class="total">
                <div style="font-size: 18px; margin-right: 16px">Total:</div>
                <div style="font-size: 20px; margin-right: 24px; font-weight: 700">$234.44</div>
                <div class="submit" :style="{ backgroundColor: configure.color_palette.color3 }">Submit Order</div>
            </div>
        </div>
        <div class="orders" v-show="component_view == 'orders'">
            <div class="title">Submitted Orders</div>
            <div class="dividing" style="margin: 14px 0 23px"></div>
            <div class="order">
                <div class="category" :style="{ color: configure.color_palette.color3 }">
                    <span>Original Order</span>
                    <span class="date">Submitted on 12:23AM</span>
                </div>
                <div class="food">
                    <div class="name">Pizza Margerita</div>
                    <div class="price">$12</div>
                </div>
                <div class="options">
                    <div class="item">
                        <div class="name">addon name *1</div>
                        <div class="price">($1.6)</div>
                    </div>
                    <div class="item">
                        <div class="name">addon name *1</div>
                        <div class="price">($1.6)</div>
                    </div>
                </div>
                <div class="dividing-dashed" style="margin: 29px 0"></div>
                <div class="category" :style="{ color: configure.color_palette.color3 }">
                    <span>Extra Order 1</span>
                    <span class="date">Submitted on 12:23AM</span>
                </div>
                <div class="food">
                    <div class="name">Pizza Margerita</div>
                    <div class="price">$12</div>
                </div>
                <div class="options">
                    <div class="item">
                        <div class="name">addon name *1</div>
                        <div class="price">($1.6)</div>
                    </div>
                    <div class="item">
                        <div class="name">addon name *1</div>
                        <div class="price">($1.6)</div>
                    </div>
                </div>
                <div class="food">
                    <div class="name">Pizza Margerita</div>
                    <div class="price">$12</div>
                </div>
            </div>
            <div class="dividing bottom-line" style="margin: 24px 0 20px"></div>
            <div class="total">
                <div style="font-size: 18px; margin-right: 16px">Total:</div>
                <div style="font-size: 20px; margin-right: 24px; font-weight: 700">$234.44</div>
                <div class="submit" :style="{ backgroundColor: configure.color_palette.color3 }">Close</div>
            </div>
        </div>
        <div class="language" v-show="component_view == 'language'">
            <div class="title">Please Select Your Language</div>
            <div class="button" :style="{ background: configure.color_palette.color3, color: configure.color_palette.color1 }">English</div>
            <div class="button">中文</div>
            <div class="button">한국어</div>
        </div>
        <div class="server" v-show="component_view == 'server'">
            <span class="iconfont icon-server" :style="{ color: configure.color_palette.color3 }"></span>
            <div class="sheet">
                <div class="item" :style="{ background: configure.color_palette.color3, color: configure.color_palette.color1 }">Napkins</div>
                <div class="dividing"></div>
                <div class="item">Water</div>
                <div class="dividing"></div>
                <div class="item">Other</div>
                <div class="item">
                    <div class="cancel">Cancel</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';
import NavPreview from '@components/common/NavPreview';
import CatePreview from '@components/common/CatePreview';
import CatePreviewLeft from '@components/common/CatePreviewLeft';

export default Vue.extend({
    name: 'Preview',
    components: {
        NavPreview,
        CatePreview,
        CatePreviewLeft,
    },
    props: {},
    data() {
        return {
            bgClass: {},
            tap_to_order: this.$store.state.app.tap_to_order,
        };
    },
    watch: {
        configure: {
            handler: function (newValue) {
                this.bgClass = {
                    background: newValue.background_cover.indexOf('#') == 0 ? newValue.background_cover : `url(${newValue.background_cover})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                };
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
            component_view: (state) => state.app.component_view,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
    },
});
</script>
<style scoped lang="less">
.features {
    position: relative;
    // border: 0.9px solid rgba(255, 255, 255, 1);
    border-radius: 18px;
    color: #000;
    font-size: 16px;
    &.horizontal {
        .cart {
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;
            width: 600px;
            height: 100%;
            .bottom-line {
                margin-top: auto !important;
            }
        }
        .orders {
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;
            width: 600px;
            height: 100%;
            .bottom-line {
                margin-top: auto !important;
            }
        }
        .detail {
            margin-top: 50px;
            .img-wrap {
                height: 150px;
            }
            .content .special {
                height: 46px;
            }
        }
    }
    .nav {
        margin-top: 23px;
    }
    .cate {
        // margin-top: 23px;
    }
    .cover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        .top {
            position: absolute;
            top: 23px;
            left: 53px;
            font-size: 50px;
        }
        .center {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 250px;
            height: 60px;
            text-align: center;
            font-size: 36px;
            line-height: 60px;
        }
        .bottom {
            position: absolute;
            right: 64px;
            bottom: 55px;
            font-size: 50px;
        }
    }
    .detail {
        position: relative;
        margin: 120px auto 0;
        padding-bottom: 25px;
        width: 554px;
        border-radius: 4px;
        background-color: #fff;
        &.large.vertical {
            position: absolute;
            bottom: 0;
            overflow: hidden;
            margin: 0;
            width: 100%;
            height: 90%;
            border-radius: 18px;
            .img-wrap {
                overflow: hidden;
                height: 350px;
                border-radius: 18px;
            }
            .limit {
                top: 280px;
            }
            .close {
                display: none;
            }
            .touch-handle {
                position: absolute;
                top: 10px;
                right: 0;
                left: 0;
                display: block;
                margin: auto;
                width: 150px;
                height: 10px;
                border-radius: 5px;
                background: #bdbfbf;
            }
        }
        &.large.horizontal {
            position: absolute;
            bottom: 0;
            display: flex;
            overflow: hidden;
            margin: 0;
            padding-bottom: 0;
            width: 100%;
            height: 90%;
            border-radius: 18px;
            .img-wrap {
                overflow: hidden;
                flex-basis: 450px;
                flex-shrink: 0;
                height: 100%;
                border-radius: 18px;
                img {
                    margin-left: -200px;
                    width: auto;
                    height: 100%;
                }
            }
            .content {
                flex-grow: 0;
                .count {
                    display: block;
                    margin-top: 40px;
                    text-align: center;
                    .steper {
                        justify-content: center;
                        margin-bottom: 10px;
                        .add {
                            margin-right: 0 !important;
                        }
                    }
                }
                .special {
                    display: none;
                }
                .addon {
                    display: block;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    .addon-desc {
                        margin-bottom: 20px;
                        padding: 12px 20px;
                        width: 100%;
                        border-radius: 6px;
                        background: #f7f7f7;
                    }
                    .addon-item {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        font-size: 16px;
                    }
                }
            }
            .limit {
                top: 540px;
                right: 500px;
            }
            .close {
                display: none;
            }
            .touch-handle {
                position: absolute;
                top: 10px;
                right: 0;
                left: 0;
                display: block;
                margin: auto;
                width: 150px;
                height: 10px;
                border-radius: 5px;
                background: #bdbfbf;
            }
        }
        .touch-handle {
            display: none;
        }
        .img-wrap {
            overflow: hidden;
            width: 100%;
            height: 210px;
            border-radius: 4px 4px 0 0;
        }
        .close {
            position: absolute;
            top: 20px;
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: #fff;
            font-size: 24px;
        }
        .limit {
            position: absolute;
            top: 157px;
            right: 45px;
            padding: 0 18px;
            height: 32px;
            border-radius: 8px;
            color: #fff;
            font-size: 14px;
            line-height: 32px;
        }
        .timg {
            width: 100%;
        }
        .content {
            padding: 42px 36px 0;
            .addon {
                display: none;
            }
            .food-name {
                font-weight: 700;
                font-size: 20px;
            }
            .price {
                margin-top: 20px;
                font-weight: 500;
                font-size: 20px;
            }
            .special {
                margin-top: 12px;
                padding: 12px 10px;
                width: 100%;
                height: 96px;
                border-radius: 6px;
                background-color: rgba(247, 247, 247, 1);
                color: #626262;
                font-size: 14px;
            }
            .count {
                display: flex;
                align-items: center;
                margin-top: 20px;
                .steper {
                    display: flex;
                    align-items: center;
                }
                .add {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    background-color: #eaeaea;
                    font-weight: bold;
                    font-size: 20px;
                }
                .button {
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    margin-left: auto;
                    padding: 0 26px;
                    height: 45px;
                    border-radius: 6px;
                    color: #fff;
                    font-weight: 500;
                    line-height: 45px;
                }
            }
        }
    }
    .cart {
        padding: 0 16px;
        width: 100%;
        border-radius: 18px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 14px;
        .title {
            padding: 23px 20px 0;
            font-weight: 700;
            font-size: 18px;
        }
        .category {
            padding: 0 20px 12px;
        }
        .food-wrap {
            display: flex;
            .food-img {
                margin-top: 5px;
                margin-left: 20px;
                width: 50px;
                height: 50px;
                border-radius: 5px;
            }
            .food {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px 0 30px;
                font-weight: 500;
                font-size: 18px;

                .price {
                    margin-right: 26px;
                    margin-left: auto;
                }
                .count {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                }
            }
            .options {
                padding: 0 20px 0 30px;
                font-weight: 400;
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 6px;
                    color: #999999;
                    .price {
                        margin-right: 100px;
                    }
                }
            }
        }
        .total {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 26px;
            padding-bottom: 27px;
            .submit {
                width: 206px;
                height: 42px;
                border-radius: 8px;
                color: #fff;
                text-align: center;
                font-weight: 700;
                font-size: 18px;
                line-height: 42px;
            }
        }
    }
    .orders {
        padding: 0 16px;
        width: 100%;
        border-radius: 18px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 14px;
        .title {
            padding: 23px 20px 0;
            font-weight: 700;
            font-size: 18px;
        }
        .order {
            margin: 0 20px;
        }
        .category {
            display: flex;
            align-items: center;
            font-size: 18px;
            .category-img {
                margin-right: 14px;
                width: 35px;
                height: 35px;
                border-radius: 8px;
            }
            .date {
                margin-left: 14px;
                color: #999999;
                font-weight: 400;
                font-size: 16px;
            }
        }
        .food {
            display: flex;
            align-items: center;
            margin-top: 16px;
            padding: 0 0px 0;
            font-weight: 500;
            font-size: 16px;
            .price {
                margin-left: auto;
            }
            .count {
                display: flex;
                align-items: center;
                font-size: 20px;
            }
        }
        .options {
            padding: 0 0px 0;
            font-weight: 400;
            font-size: 14px;
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 6px;
                color: #999999;
            }
        }
        .button {
            margin-left: 20px;
            width: 130px;
            height: 29px;
            border: 1px solid #fff;
            border-radius: 5.6px;
            text-align: center;
            line-height: 29px;
        }
        .total {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 26px;
            padding-bottom: 27px;
            .submit {
                width: 140px;
                height: 42px;
                border-radius: 8px;
                color: #fff;
                text-align: center;
                font-weight: 700;
                font-size: 18px;
                line-height: 42px;
            }
        }
    }
    .language {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 396px;
        height: 272px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 18px;
        .title {
            margin-top: 24px;
            margin-bottom: 24px;
            text-align: center;
            font-weight: 500;
        }
        .button {
            margin: 0 auto 8px;
            width: 331px;
            height: 51px;
            border-radius: 8px;
            background-color: rgba(120, 120, 128, 0.16);
            text-align: center;
            font-weight: 500;
            line-height: 51px;
        }
    }
    .server {
        position: absolute;
        top: 15px;
        right: 25px;
        text-align: right;

        .icon-server {
            font-size: 26px;
        }
        .sheet {
            margin-top: 10px;
            padding: 18px 0px 10px;
            width: 188px;
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 1);
            text-align: center;
            font-size: 18px;
            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                line-height: 48px;
                .cancel {
                    margin: auto;
                    width: 137px;
                    height: 38px;
                    border-radius: 8px;
                    background-color: #e7e7e8;
                    line-height: 38px;
                }
            }
            .dividing {
                margin: auto;
                width: 158px;
            }
        }
    }
}
</style>
