module.exports = {
    /**
     * 主题配置列表
     */
    THEME_LIST: {
        basic: {
            theme_name: 'basic', //主题名称
            border_img: '', //图片边框图片
            quantity_border_img: '', //数量选择边框图片
            quantity_bg_img: '', //数量选择底色背景图片
            category_img: '', //类目选择边框图片
            color_palette: {
                color1: '#FFFFFF',
                color2: '#FFFFFF',
                color3: '#1c1c1e',
            },
            font_family: {
                store_name: 'Roboto',
                category_name: 'Roboto',
                item_name: 'Roboto',
                quantity: 'Roboto',
                price: 'Roboto',
                paragraph: 'Roboto',
                cover: 'Roboto',
            },
            background_cover: {
                vertical: '#ffffff',
                horizontal: '#ffffff',
            }, //背景
        },
        classic: {
            theme_name: 'classic', //主题名称
            border_img: '', //图片边框图片
            quantity_border_img: '', //数量选择边框图片
            quantity_bg_img: '', //数量选择底色背景图片
            category_img: '', //类目选择边框图片
            color_palette: {
                color1: '#C6A447',
                color2: '#D6CCAE',
                color3: '#000000',
            },
            font_family: {
                store_name: 'Lora',
                category_name: 'Lora',
                item_name: 'Lora',
                quantity: 'Lora',
                price: 'Lora',
                paragraph: 'Lora',
                cover: 'Lora',
            },
            background_cover: {
                vertical: 'https://d3puj4wh202ha4.cloudfront.net/sm/back1_vertical.png',
                horizontal: 'https://d3puj4wh202ha4.cloudfront.net/sm/back1_horizontal.png',
            }, //背景
        },
        modern: {
            theme_name: 'modern', //主题名称
            border_img: '', //图片边框图片
            quantity_img: '', //数量选择边框图片
            category_img: '', //类目选择边框图片
            color_palette: {
                color1: '#E9AD3C',
                color2: '#CBC99B',
                color3: '#023435',
            },
            font_family: {
                store_name: 'Oriya MN',
                category_name: 'Oriya MN',
                item_name: 'Oriya MN',
                quantity: 'Oriya MN',
                price: 'Oriya MN',
                paragraph: 'Oriya MN',
                cover: 'Oriya MN',
            },
            background_cover: {
                vertical: 'https://d3puj4wh202ha4.cloudfront.net/sm/back2_vertical.png',
                horizontal: 'https://d3puj4wh202ha4.cloudfront.net/sm/back2_horizontal.png',
            }, //背景
        },
    },
};
