<template>
    <a-modal class="milePop" title="Edit Image" :width="600" v-model="visible" ok-text="Confirm" @ok="confirm">
        <div class="flexbox">
            <div :class="['flex-item', type == 1 ? 'active' : '']">
                <a-upload
                    name="file"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    accept="image/*"
                    reject="uploadReject"
                    :before-upload="(file) => beforeUpload(file)"
                    :disabled="type != 1"
                >
                    <img class="upload-block" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">Upload</div>
                    </div>
                </a-upload>
                <a-button :type="type == 1 ? 'primary' : ''" @click="type = 1">Use Image</a-button>
            </div>
            <div :class="['flex-item', type == 2 ? 'active' : '']">
                <div :class="['color-block', type == 2 ? '' : 'not-allowed']" :style="{ backgroundColor: colorCode }" @click="openColor"></div>
                <a-button :type="type == 2 ? 'primary' : ''" @click="type = 2">Use Solid Color</a-button>
            </div>
        </div>
        <!-- pop -->
        <color-picker ref="color" :active="activeColor" @confirm="colorConfirm"></color-picker>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@kits/axiosApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import ColorPicker from '@components/pop/ColorPicker';

export default Vue.extend({
    name: 'EditBackground',
    components: {
        ColorPicker,
    },
    props: {
        active: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            visible: false,
            type: 1, //1:image,2:solid color
            imageUrl: '',
            colorCode: '',
            activeColor: '',
        };
    },
    watch: {
        active: {
            handler(newValue, oldValue) {
                if (newValue.indexOf('#') > -1) {
                    this.type = 2;
                    this.colorCode = newValue;
                } else {
                    this.type = 1;
                    this.imageUrl = newValue;
                    this.colorCode = this.configure.color_palette.color3;
                }
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            pageSetting: 'app/get_page_setting',
            sid: 'app/get_sid',
        }),
        ...mapState({
            aj_host: (state) => state.aj_host,
            configure: (state) => state.app.configure,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING',
        }),
        show() {
            this.visible = true;
        },
        colorConfirm(e) {
            this.colorCode = e;
        },
        openColor(index) {
            if (this.type != 2) {
                return;
            }
            this.activeColor = this.colorCode;
            this.$refs.color.show();
        },
        uploadReject() {
            this.$notification['warning']({
                message: 'Errot type',
                description: 'Only supports image upload.',
            });
        },
        async beforeUpload(file) {
            if (file.size > 10485760) {
                this.$notification['warning']({
                    message: 'Image size cannot exceed 10MB',
                });
                return false;
            }
            var formData = new FormData();
            formData.append('file', file);
            let time = Date.parse(new Date());
            var randomNum = '';
            for (var i = 0; i < 4; i++) {
                var a = Math.floor(Math.random() * 9);
                randomNum += a;
            }
            formData.append('name', this.sid + '_super_menu_img_' + time + randomNum);
            await this.uploadImg(formData).then((res) => {
                this.imageUrl = res.file_url;
            });
            return false;
        },
        uploadImg(params) {
            return new Promise((resolve, reject) => {
                this.setLoading({
                    show_loading: true,
                });
                axiosApi
                    .post(this.aj_host + '/weapp/admin/supermenu/upload', params, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((res) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        resolve(res.data.data.data);
                    })
                    .catch((err) => {
                        this.setLoading({
                            show_loading: false,
                        });
                        reject(err);
                    });
            });
        },
        confirm() {
            if ((this.type == 1 && !this.imageUrl) || (this.type == 2 && !this.colorCode)) {
                this.$notification['warning']({
                    message: 'Please upload image or select color',
                });
                return;
            }
            this.$emit('confirm', this.type == 1 ? this.imageUrl : this.colorCode);
            this.visible = false;
        },
    },
});
</script>
<style scoped lang="less">
.flexbox {
    display: flex;
    justify-content: space-between;
    .flex-item {
        text-align: center;
        &.active /deep/ .ant-upload.ant-upload-select-picture-card {
            border: 2px dashed @main-color;
        }
        &.active .color-block {
            border: 2px dashed @main-color;
        }
    }
    .avatar-uploader {
        display: block;
        overflow: hidden;
        margin-bottom: 16px;
        width: 210px;
        height: 255px;
    }
    & /deep/ .ant-upload {
        padding: 0;
        width: 210px;
        height: 250px;
        font-size: 18px;
    }

    .upload-block {
        max-height: 230px;
        width: 100%;
        height: auto;
    }
    .color-block {
        margin-bottom: 16px;
        width: 210px;
        height: 255px;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        &.not-allowed {
            cursor: not-allowed;
        }
    }
}
</style>
