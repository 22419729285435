<template>
    <div class="middle">
        <div class="box">
            <div class="title">Required Components:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="all-list">
                <div class="item">
                    <span class="name">Navigation Bar</span>
                    <span class="view" @click="showView('nav')">view</span>
                </div>
                <div class="item">
                    <span class="name">Category Panel</span>
                    <span class="view" @click="showView('cate')">view</span>
                </div>
                <div class="item">
                    <span class="name">Menu Cover</span>
                    <span class="view" @click="showView('cover')">view</span>
                </div>
                <div class="item">
                    <span class="name">Item Detail Popup</span>
                    <span class="view" @click="showView('detail')">view</span>
                </div>
                <div class="item">
                    <span class="name">Cart</span>
                    <span class="view" @click="showView('cart')">view</span>
                </div>
                <div class="item">
                    <span class="name">Submitted Orders</span>
                    <span class="view" @click="showView('orders')">view</span>
                </div>
                <div class="item">
                    <span class="name">Language Options</span>
                    <span class="view" @click="showView('language')">view</span>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="title">Optional Components:</div>
            <div class="dividing" style="margin: 12px 0 24px"></div>
            <div class="all-list">
                <div class="item">
                    <span class="name">Call Server Panel</span>
                    <span class="view" @click="showView('server')">view</span>
                    <a-checkbox v-model="callServer" @change="onChangeCall"> </a-checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'EtitorFeatures',
    props: {},
    data() {
        return {
            visible: false,
            callServer: false,
        };
    },
    watch: {
        'configure.call_server_display': {
            handler: function (newVlaue) {
                this.callServer = newVlaue == 1 ? true : false;
            },
            immediate: true,
        },
    },
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({}),
        ...mapState({
            configure: (state) => state.app.configure,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            set_component_view: 'app/SET_COMPONENT_VIEW',
            set_configure: 'app/SET_CONFIGURE',
        }),

        show() {
            this.visible = true;
        },

        showView(view) {
            this.set_component_view(view);
        },
        onChangeCall(e) {
            let configure = this._.cloneDeep(this.configure);
            configure.call_server_display = e.target.checked ? 1 : 0;
            this.set_configure(configure);
        },
    },
});
</script>
<style scoped lang="less">
.middle {
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 30px 20px;
    width: 370px;
    background-color: #d6d6d6;
    .box {
        box-sizing: border-box;
        margin-bottom: 24px;
        padding: 20px 25px;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        .title {
            font-weight: bold;
            font-size: 20px;
        }
        .subhead {
            font-weight: 600;
        }
    }
    .all-list {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 18px;
            .name {
                font-weight: 500;
                font-size: 18px;
            }
            .view {
                margin-right: 20px;
                margin-left: auto;
                color: @main-color;
                cursor: pointer;
            }
        }
    }
}
</style>
