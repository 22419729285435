<template>
    <a-modal class="milePop" :title="'Choose an item from category <' + menu_dict[active].name + '>'" :width="600" v-model="visible" @cancel="onCancel" v-if="active">
        <a-radio-group class="ant-radio-group" v-model="activeFood" v-if="type == 'add'">
            <a-radio class="radio" :value="item.fid" v-for="item in menu_dict[active].list" :key="item.fid" v-show="addedFoodList(active).indexOf(item.fid) == -1">
                <div class="label">{{ item.fname }}</div>
            </a-radio>
        </a-radio-group>
        <a-radio-group class="ant-radio-group" v-model="activeFood" v-else>
            <a-radio class="radio" :value="item.fid" v-for="item in menu_dict[active].list" :key="item.fid">
                <div class="label">{{ item.fname }}</div>
            </a-radio>
        </a-radio-group>
        <template slot="footer">
            <div class="foot">
                <a-button key="delete" type="danger" @click="handleDelete" v-if="type == 'add'"> Delete Card </a-button>
                <a-button key="back" @click="handleCancel" style="margin-left: auto"> Cancel </a-button>
                <a-button key="confirm" type="primary" @click="confirm"> Confirm </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'FoodPicker',
    components: {},
    props: {
        active: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            visible: false,
            activeFood: '',
        };
    },
    watch: {},
    async created() {},
    mounted() {},
    computed: {
        ...mapGetters({
            addedFoodList: 'app/get_added_food_list',
        }),
        ...mapState({
            configure: (state) => state.app.configure,
            menu_dict: (state) => state.food.menu_dict,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),

        show() {
            this.visible = true;
        },
        handleCancel() {
            this.visible = false;
            this.activeFood = '';
        },
        onCancel() {
            this.visible = false;
            this.activeFood = '';
        },
        handleDelete() {
            this.$confirm({
                title: 'Warning',
                content: 'Card and the item contained will be removed.',
                okText: 'Delete Card',
                cancelText: 'Cancel',
                onOk: () => {
                    this.$emit('deletecard');
                    this.visible = false;
                },
            });
        },
        confirm() {
            if (!this.activeFood) {
                this.$notification['warning']({
                    message: 'No Food selected',
                });
                return;
            }
            this.$emit('confirm', this.activeFood);
            this.visible = false;
            this.activeFood = '';
        },
    },
});
</script>
<style scoped lang="less">
/deep/ .ant-modal-body {
    overflow: hidden;
    overflow-y: auto;
    max-height: 550px;
}

.ant-radio-group {
    width: 100%;
}
.radio {
    min-height: 30px;
    width: 100%;
    line-height: 20px;
}
.radio /deep/ .label {
    margin: -20px 0 0 28px;
    padding-right: 20px;
    width: 100%;
    white-space: break-spaces;
}

.foot {
    display: flex;
}
</style>
