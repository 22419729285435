import axios from 'axios';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 300000,
});

service.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem('token') || '';
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default service;
// /**
//  * get方法，对应get请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
// export function get(url, params) {
//     return new Promise((resolve, reject) => {
//         service
//             .get(url, {
//                 params: params,
//             })
//             .then((res) => {
//                 resolve(res.data)
//             })
//             .catch((err) => {
//                 reject(err.data)
//             })
//     })
// }
// /**
//  * post方法，对应post请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
// export function post(url, params) {
//     return new Promise((resolve, reject) => {
//         service
//             .post(url, params)
//             .then((res) => {
//                 resolve(res)
//             })
//             .catch((err) => {
//                 reject(err.data)
//             })
//     })
// }
