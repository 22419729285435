module.exports = {
    /**
     * layout配置列表
     */
    LAYOUT_LIST: {
        11: {
            name: 11,
            cards: [
                {
                    x: 120, //坐标x
                    y: 10, //坐标y
                    type: 'card42',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        12: {
            name: 12,
            cards: [
                {
                    x: 50, //坐标x
                    y: 10, //坐标y
                    type: 'card41',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        21: {
            name: 21,
            cards: [
                {
                    x: 50, //坐标x
                    y: 30, //坐标y
                    type: 'card31',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 180, //坐标x
                    y: 500, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        22: {
            name: 22,
            cards: [
                {
                    x: 40, //坐标x
                    y: 10, //坐标y
                    type: 'card32',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 700, //坐标x
                    y: 80, //坐标y
                    type: 'card22',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        31: {
            name: 31,
            cards: [
                {
                    x: 130, //坐标x
                    y: 10, //坐标y
                    type: 'card32',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 40, //坐标x
                    y: 590, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 450, //坐标x
                    y: 590, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        32: {
            name: 32,
            cards: [
                {
                    x: 27, //坐标x
                    y: 15, //坐标y
                    type: 'card32',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 684, //坐标x
                    y: 15, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 684, //坐标x
                    y: 405, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        41: {
            name: 41,
            cards: [
                {
                    x: 90, //坐标x
                    y: 10, //坐标y
                    type: 'card22',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 500, //坐标x
                    y: 10, //坐标y
                    type: 'card22',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 90, //坐标x
                    y: 400, //坐标y
                    type: 'card22',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 500, //坐标x
                    y: 400, //坐标y
                    type: 'card22',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        42: {
            name: 42,
            cards: [
                {
                    x: 170, //坐标x
                    y: 10, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 170, //坐标x
                    y: 300, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 50, //坐标x
                    y: 600, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 490, //坐标x
                    y: 600, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        43: {
            name: 43,
            cards: [
                {
                    x: 10, //坐标x
                    y: 30, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 530, //坐标x
                    y: 30, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 10, //坐标x
                    y: 320, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 530, //坐标x
                    y: 320, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        44: {
            name: 44,
            cards: [
                {
                    x: 10, //坐标x
                    y: 30, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 10, //坐标x
                    y: 320, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 630, //坐标x
                    y: 80, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 630, //坐标x
                    y: 350, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        51: {
            name: 51,
            cards: [
                {
                    x: 70, //坐标x
                    y: 35, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 450, //坐标x
                    y: 35, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 70, //坐标x
                    y: 260, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 450, //坐标x
                    y: 260, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 170, //坐标x
                    y: 500, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        52: {
            name: 52,
            cards: [
                {
                    x: 15, //坐标x
                    y: 35, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 360, //坐标x
                    y: 35, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 705, //坐标x
                    y: 35, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 60, //坐标x
                    y: 320, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 500, //坐标x
                    y: 300, //坐标y
                    type: 'card21',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        61: {
            name: 61,
            cards: [
                {
                    x: 80, //坐标x
                    y: 35, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 450, //坐标x
                    y: 35, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 80, //坐标x
                    y: 270, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 450, //坐标x
                    y: 270, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 80, //坐标x
                    y: 505, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 450, //坐标x
                    y: 505, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        62: {
            name: 62,
            cards: [
                {
                    x: 10, //坐标x
                    y: 60, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 360, //坐标x
                    y: 60, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 710, //坐标x
                    y: 60, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 10, //坐标x
                    y: 330, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 360, //坐标x
                    y: 330, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 710, //坐标x
                    y: 330, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        81: {
            name: 81,
            cards: [
                {
                    x: 60, //坐标x
                    y: 0, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 460, //坐标x
                    y: 0, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 60, //坐标x
                    y: 200, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 460, //坐标x
                    y: 200, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 60, //坐标x
                    y: 400, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 460, //坐标x
                    y: 400, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 60, //坐标x
                    y: 600, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 460, //坐标x
                    y: 600, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
        82: {
            name: 82,
            cards: [
                {
                    x: 10, //坐标x
                    y: 10, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 350, //坐标x
                    y: 10, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 690, //坐标x
                    y: 10, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 10, //坐标x
                    y: 200, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 350, //坐标x
                    y: 200, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 690, //坐标x
                    y: 200, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 10, //坐标x
                    y: 400, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
                {
                    x: 350, //坐标x
                    y: 400, //坐标y
                    type: 'card11',
                    img_url: '', //替换菜品的默认图片
                    border: 1, // 0/1
                    fid: '',
                },
            ],
        },
    },
};
