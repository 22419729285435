require('es6-promise').polyfill(); //es6 promise
require('isomorphic-fetch'); //fetch

import Vue from 'vue';
import App from './views/App.vue';
import store from './store/index.js';
import router from './router';
import VueLazyload from 'vue-lazyload';
import _ from 'lodash';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import '@assets/styles/font.less';
import '@assets/iconfont/iconfont.css';

Vue.use(Antd);

Vue.prototype._ = _;
Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1,
});

// 自定义指令 元素宽高变化
Vue.directive('resize', {
    bind(el, binding) {
        let width = '',
            height = '';
        function isReize() {
            const style = document.defaultView.getComputedStyle(el);
            if (width !== style.width || height !== style.height) {
                binding.value({ width: style.width, height: style.height });
            }
            width = style.width;
            height = style.height;
        }
        el.__vueSetInterval__ = setInterval(isReize, 300);
    },
    unbind(el) {
        clearInterval(el.__vueSetInterval__);
    },
});

export function createApp() {
    const app = new Vue({
        el: '#application',
        router,
        store,
        render: (h) => h(App),
    });

    return { app, router };
}

window.onload = () => createApp();
